import { useGSAP } from "@gsap/react";
import LetsConnect from "../LetsConnect";
import Hero from "../sections/Hero";
import Overview from "../sections/Overview";
import WhatWeCanDo from "../sections/WhatWeCanDo";


import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

const OurServices = () => {

    
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useGSAP(() => {
        const loadTl = gsap.timeline({ defaults: { opacity: 0, ease: 'ease-in', duration: 1 } });
    
        const pageFadeIn = () => {
          const tl = gsap.timeline();
          tl.from('body', { opacity: 0, ease: 'linear', duration: 1 });
          return tl;
        };
        loadTl.add(pageFadeIn());
    });

    useEffect(() => {
        // Function to update window width
        const handleResize = () => {
          setWindowWidth(window.innerWidth);
        };
    
        // Add event listener
        window.addEventListener('resize', handleResize);
    
        // Clean up event listener on component unmount
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);


    const sectionRefs = useRef([]);

    const scrollToSection = (index) => {
        const offsetPosition = sectionRefs.current[index].offsetTop - 90;
        window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth',
        });    
    };

    const location = useLocation();

    const sectionMapping = {
      uiux: "UI/UX Design",
      appdev: "Application Development",
      webdev: "Web development",
      aiml: "AI and ML",
      staff: "Staff Augmentation",
  };

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const section = queryParams.get("section");
        // Scroll to the section dynamically based on the query parameter
        if (section && sectionMapping[section]) {
          // Delay the scroll to allow rendering time
          setTimeout(() => {
              scrollToSection(sectionMapping[section]);
          }, 500);
      }
    }, [location]);
      


    return (
        <>
            <Hero title="Our services" desc="We provide tailored technology solutions like UI/UX design, web development, app development, AI/ML, and Staff Augmentation. Experience how our expertise drives innovation and success for your business." page="our-services"/>
        {
            windowWidth > 1024 ?
            (
                <>
                    <WhatWeCanDo scrollToSection={scrollToSection} sectionRefs={sectionRefs}/>
                    <Overview scrollToSection={scrollToSection} sectionRefs={sectionRefs}/>
                </>
            )
            :
            (
                <>
                    <Overview scrollToSection={scrollToSection} sectionRefs={sectionRefs}/>
                
                </>
            )
        }
            <LetsConnect/>
        </>
    );
}
 
export default OurServices;