import { Link } from "react-router-dom";
import BrandLogo from "./utilities/BrandLogo";

const LetsConnect = () => {
    return (
        <>
            <section className="_lets-connect">
                <div className="_lets-connect_wrapper _container">
                    <div className="_lets-connect_banner_wrapper">
                        <img className="_lets-connect_banner" src="./assets/media/pages/about-us/lets-connect/Connect.png"/>
                    </div>
                    <div className="_lets-connect_info_wrapper">
                        <div className="_lets-connect_info">
                            <h3 className="_lets-connect_title _h1">
                                You are a click away from building your dream.
                            </h3>
                            <p></p>
                        </div>
                        <Link to={"/contact-us"} className="_button _is_primary _is_large _lets-connect_button">
                            Let's Connect
                        </Link>
                        <BrandLogo brandColor={"#000"}/>
                    </div>
                </div>
            </section>
        </>
    );
}
 
export default LetsConnect;