import { initializeApp } from 'firebase/app';
import { getRemoteConfig, fetchAndActivate, getString } from 'firebase/remote-config';

// Your Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCRXsDPjf3u-dmdPTs4TcatPqKKEd0b9gk",
  authDomain: "mindcreswebsite.firebaseapp.com",
  projectId: "mindcreswebsite",
  storageBucket: "mindcreswebsite.appspot.com",
  messagingSenderId: "282039816576",
  appId: "1:282039816576:web:2f53a86c335dee4965b165",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Get a reference to the Remote Config service
const remoteConfig = getRemoteConfig(app);
remoteConfig.settings = {
  minimumFetchIntervalMillis: 3600000, // 1 hour
};

export { remoteConfig, fetchAndActivate, getString };