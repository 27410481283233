const ClientReviewCard = ({name, review}) => {
    return (
        <>
            <div className="_client-review-card">
                <div className="_client-review-card_media_wrapper">
                    <img className="_client-review-card_media" src="/assets/media/pages/about-us/client-review/pic.png" />
                </div>
                <div className="_client-review-card_mid-section">
                    <div className="_client-review-card_name"> {name}</div>
                    <div className="_client-review-card_comapny-name">
                        {/* Founder and Owner at */}
                    </div>
                    <div className="_client-review-card_description">
                        {review}
                    </div>
                </div>
            </div>

        </>
    );
}
 
export default ClientReviewCard;