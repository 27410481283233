import { Link } from "react-router-dom";

export const Tab = ({ tab, visibleTab, setVisibleTab }) => (
    <li
      onClick={() => setVisibleTab(tab._tabId)}
      className={visibleTab === tab._tabId ? "_tab_title _tab_title--active" : "_tab_title"}
    >
      {tab.tabName}
    </li>
);

export const TabContent = ({ tab, visibleTab, formEntries }) => {    
    return (
        <>
        
            {
                // formEntries.filter(entry => entry.associatedTabs.includes(tab._tabId) && tab._tabId == visibleTab).map(entry => (
                formEntries && formEntries?.map(entry => (
                    <>
                        <div className="_career-section_card">
                            <div key={entry._id} className="_career-section_card_info">
                                <span className="_career-section_card_info_title">
                                    {entry.jobName}
                                </span>
                                
                                <div key={entry._id} className="_career-section_card_info_desc">
                                    {
                                        entry?.jobDesc?.desc && entry?.jobDesc?.desc.map((desc, index) => (
                                            <span key={index}>{desc}</span>
                                        ))
                                    }
                                    {/* {entry.jobDesc} */}
                                </div>
                            </div>
                            <Link to={`/job-detail/${entry._id}`} className="_button _is_medium _is_primary _career-section_card_info_button">
                                Apply now
                            </Link>
                        </div>
                    </>
                ))
            }
        </>
    )
};