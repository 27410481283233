import { Splide, SplideSlide } from "@splidejs/react-splide";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ServicesOverview = ({sectionRefs}) => {

    return (
        <>
            <div className="_service-overview section" id="uiux-section" ref={ref => sectionRefs.current["UI/UX Design"] = ref}>
                <div className="_service-overview_wrapper _service-overview_wrapper_ux _container">
                    <div className="_service-overview_info">
                        <h6 className="_service-overview_title _sub_h2 title">UI/UX Design</h6>
                        <p className="_service-overview_desc desc">
                            <span>
                                At Mindcres Technologies, we craft user-centered interfaces and experiences that drive engagement and satisfaction. Our four-step UI/UX design process ensures your digital product stands out:
                            </span>
                            <ul className="_service-overview_keys">
                                <li className="_service-overview_key _service-overview_key_ux">
                                    <span>Discovery & Research:</span>
                                    
                                    We begin by understanding your brand and target audience through in-depth research. 
                                    
                                </li>
                                <li className="_service-overview_key _service-overview_key_ux">
                                    <span>Wireframing & Prototyping:</span>
                                    
                                    Our team develops detailed wireframes and interactive prototypes to outline the user journey and interface. 
                                    
                                </li>
                                <li className="_service-overview_key _service-overview_key_ux">
                                    <span>Design & Development:</span>
                                    
                                    Our designers create visually engaging UI elements, which our developers then integrate into a high-performing, responsive product tailored to your needs.
                                    
                                </li>
                                <li className="_service-overview_key _service-overview_key_ux">
                                    <span>Testing & Refinement:</span>
                                    
                                    We perform extensive usability testing to uncover and address any issues. This iterative process ensures that your final product provides an exceptional user experience.
                                    
                                </li>
                            </ul>
                        <div className="_service-overview_proccess">
                        Collaborate with us to bring your digital vision to life with expertly crafted UI/UX design.
                        </div>
                        </p>
                        {/* <div className="_service-overview_proccess">
                            <span className="_service-overview_proccess_item">
                                <img src="./assets/media/pages/our-services/overview/search.png"/>
                                Discovery
                            </span>
                            <span className="_service-overview_proccess_item">
                                <img src="./assets/media/pages/our-services/overview/idea.png"/>
                                Ideation
                            </span>
                            <span className="_service-overview_proccess_item">
                                <img src="./assets/media/pages/our-services/overview/design.png"/>
                                Design
                            </span>
                            <span className="_service-overview_proccess_item">
                                <img src="./assets/media/pages/our-services/overview/deliver.png"/>
                                Deliver
                            </span>
                        </div> */}
                    </div>
                    <div className="_service-overview_media">
                        <div className="_service-overview_proccess _service-overview_proccess_responsive">
                            Collaborate with us to bring your digital vision to life with expertly crafted UI/UX design.
                        </div>
                        {/* <div className="_service-overview_proccess _service-overview_proccess_responsive">
                            <span className="_service-overview_proccess_item">
                                <img src="./assets/media/pages/our-services/overview/search.png"/>
                                Discovery
                            </span>
                            <span className="_service-overview_proccess_item">
                                <img src="./assets/media/pages/our-services/overview/idea.png"/>
                                Ideation
                            </span>
                            <span className="_service-overview_proccess_item">
                                <img src="./assets/media/pages/our-services/overview/design.png"/>
                                Design
                            </span>
                            <span className="_service-overview_proccess_item">
                                <img src="./assets/media/pages/our-services/overview/deliver.png"/>
                                Deliver
                            </span>
                        </div> */}
                        <img className="_service-overview_media_banner banner" src="./assets/media/pages/our-services/overview/iPad-mockup.png"/>
                    </div>
                </div>
            </div>
            <div className="_service-overview _service-overview_app-development section" id="appdev-section" ref={ref => sectionRefs.current["Application Development"] = ref}>
                <div className="_service-overview_wrapper _container">
                    <div className="_service-overview_info">
                        <h6 className="_service-overview_title _sub_h2 title">Application Development</h6>
                        <p className="_service-overview_desc desc">
                            <span>
                            We excel in creating high-performance applications through a precise four-step process, whether you need native Android, iOS, or hybrid apps :
                            </span>
                            <ul className="_service-overview_keys">
                                <li className="_service-overview_key">
                                    <span>Discovery & Planning:</span>
                                    
                                    We start by understanding your business goals and audience, conducting thorough research and consultations to define project scope and create a tailored strategic plan.                                </li>
                                <li className="_service-overview_key">
                                    <span>Design & Prototyping:</span>
                                    
                                    Our team designs intuitive interfaces and builds interactive prototypes for Android, iOS, and hybrid apps, refining user experience to align with your vision before development.
                                    
                                </li>
                                <li className="_service-overview_key">
                                    <span>Development & Integration:</span>
                                    
                                    We create robust applications using native technologies for Android and iOS, or Flutter for efficient cross-platform solutions, focusing on high-quality, feature-rich performance.
                                    
                                </li>
                                <li className="_service-overview_key">
                                    <span>Testing & Launch:</span>
                                    
                                    We rigorously test to ensure bug-free, optimal performance, manage the launch process, and provide ongoing support for continued success.
                                </li>
                            </ul>
                            <div className="_service-overview_proccess desc">
                            Partner with us to create an exceptional app that stands out in the digital landscape.
                            </div>
                        </p>
                    </div>
                    <div className="_service-overview_media banner">
                        <img className="_service-overview_media_item" src="./assets/media/pages/our-services/overview/iphone2.png"/>
                        <img className="_service-overview_media_item" src="./assets/media/pages/our-services/overview/iphone1.png"/>
                        <img className="_service-overview_media_item" src="./assets/media/pages/our-services/overview/iphone3.png"/>
                    </div>
                </div>
                <div className="_service-overview_app-development_techs">
                    <Splide 
                        options={{
                            type: "loop",
                            gap: "20px",
                            drag: "free",
                            arrows: false,
                            focus: 'center',
                            pagination: false,
                            // autoplay:true,
                            interval     : 2500,
                            resetProgress: true,
                            breakpoints: {
                                769: {
                                    perPage:3
                                },
                                6400: {
                                    perPage:8,
                                },
                            },
                            autoScroll: {
                                pauseOnHover: false,
                                pauseOnFocus: false,
                                rewind: true,
                                speed: 0.8,
                            },
                        }}
                        extensions={{ AutoScroll }}
                    >
                        <SplideSlide>
                            <img className="_service-overview_app-development_tech" src="./assets/media/pages/our-services/overview/react.svg"/>
                        </SplideSlide>
                        <SplideSlide>
                            <img className="_service-overview_app-development_tech" src="./assets/media/pages/our-services/overview/swift.svg"/>
                         </SplideSlide>
                        <SplideSlide>
                            <img className="_service-overview_app-development_tech" src="./assets/media/pages/our-services/overview/python.svg"/>
                        </SplideSlide>
                        <SplideSlide>
                            <img className="_service-overview_app-development_tech" src="./assets/media/pages/our-services/overview/nodejs.svg"/>
                        </SplideSlide>
                        <SplideSlide>
                            <img className="_service-overview_app-development_tech" src="./assets/media/pages/our-services/overview/django.svg"/>
                        </SplideSlide>
                        <SplideSlide>
                            <img className="_service-overview_app-development_tech" src="./assets/media/pages/our-services/overview/dart.svg"/>
                        </SplideSlide>
                        <SplideSlide>
                            <img className="_service-overview_app-development_tech" src="./assets/media/pages/our-services/overview/java.svg"/>
                        </SplideSlide>
                    </Splide>
                </div>
            </div>
            <div className="_service-overview _service-overview_web-development section" id="webdev-section" ref={ref => sectionRefs.current["Web development"] = ref}>
                <div className="_service-overview_wrapper _service-overview_wrapper_block _container">
                    <div className="_service-overview_info _service-overview_info_block">
                        <h6 className="_service-overview_title _sub_h2 title">Web Development</h6>
                        <p className="_service-overview_desc desc">
                            <span>
                            At Mindcres, we are committed to delivering websites that are not only visually striking but also highly functional. Our web development process starts with a deep dive into your business goals and target audience. This research phase helps us craft a tailored strategy that aligns with your vision and objectives.
                            </span>
                            <span>
                            Next, our design team creates engaging wireframes and interactive prototypes to map out the user experience and interface. This ensures that every design element is thoughtfully refined before we proceed to development.
                            </span>
                        </p>
                    </div>
                </div>
                <div className="_service-overview_wrapper _service-overview_wrapper_2 _container">
                    <div className="_service-overview_info _service-overview_info_2 desc">
                        <p className="_service-overview_desc">
                        <span>
                            Once the design is finalized, our developers build your site with clean, efficient code, integrating essential features to ensure performance and scalability. We focus on creating a responsive website that performs seamlessly across all devices.
                            </span>
                            <span>
                            Before launch, we conduct rigorous testing to iron out any issues and optimize the site’s functionality. After a smooth launch, we provide ongoing support to ensure your website continues to perform at its best.
                            </span>
                        </p>
                        <p>Collaborate with us to create a compelling website that achieves your business goals.</p>
                    </div>
                    <div className="_service-overview_media banner">
                        <img src="./assets/media/pages/our-services/overview/web-dev.png"/>
                    </div>
                </div>
                <div className="_service-overview_app-development_techs">
                    <Splide 
                        options={{
                            type: "loop",
                            gap: "20px",
                            drag: "free",
                            arrows: false,
                            focus: 'center',
                            pagination: false,
                            // autoplay:true,
                            interval     : 2500,
                            resetProgress: true,
                            breakpoints: {
                                769: {
                                    perPage:3
                                },
                                6400: {
                                    perPage:8,
                                },
                            },
                            autoScroll: {
                                pauseOnHover: false,
                                pauseOnFocus: false,
                                rewind: true,
                                speed: 0.8,
                            },
                        }}
                        extensions={{ AutoScroll }}
                    >
                        <SplideSlide>
                            <img className="_service-overview_app-development_tech" src="./assets/media/pages/our-services/overview/react.svg"/>
                        </SplideSlide>
                        <SplideSlide>
                            <img className="_service-overview_app-development_tech" src="./assets/media/pages/our-services/overview/typescript.svg"/>
                         </SplideSlide>
                        <SplideSlide>
                            <img className="_service-overview_app-development_tech" src="./assets/media/pages/our-services/overview/python.svg"/>
                        </SplideSlide>
                        <SplideSlide>
                            <img className="_service-overview_app-development_tech" src="./assets/media/pages/our-services/overview/nodejs.svg"/>
                        </SplideSlide>
                        <SplideSlide>
                            <img className="_service-overview_app-development_tech" src="./assets/media/pages/our-services/overview/javascript.svg"/>
                        </SplideSlide>
                        <SplideSlide>
                            <img className="_service-overview_app-development_tech" src="./assets/media/pages/our-services/overview/html5.svg"/>
                        </SplideSlide>
                        <SplideSlide>
                            <img className="_service-overview_app-development_tech" src="./assets/media/pages/our-services/overview/java.svg"/>
                        </SplideSlide>
                        <SplideSlide>
                            <img className="_service-overview_app-development_tech" src="./assets/media/pages/our-services/overview/gitlab.svg"/>
                        </SplideSlide>
                        <SplideSlide>
                            <img className="_service-overview_app-development_tech" src="./assets/media/pages/our-services/overview/wordpress-blue.svg"/>
                        </SplideSlide>
                        <SplideSlide>
                            <img className="_service-overview_app-development_tech" src="./assets/media/pages/our-services/overview/angular.svg"/>
                        </SplideSlide>
                        <SplideSlide>
                            <img className="_service-overview_app-development_tech" src="./assets/media/pages/our-services/overview/bootstrap.svg"/>
                        </SplideSlide>
                        <SplideSlide>
                            <img className="_service-overview_app-development_tech" src="./assets/media/pages/our-services/overview/css3.svg"/>
                        </SplideSlide>
                        <SplideSlide>
                            <img className="_service-overview_app-development_tech" src="./assets/media/pages/our-services/overview/npm.svg"/>
                        </SplideSlide>
                        <SplideSlide>
                            <img className="_service-overview_app-development_tech" src="./assets/media/pages/our-services/overview/redux.svg"/>
                        </SplideSlide>
                        <SplideSlide>
                            <img className="_service-overview_app-development_tech" src="./assets/media/pages/our-services/overview/sass.svg"/>
                        </SplideSlide>
                        <SplideSlide>
                            <img className="_service-overview_app-development_tech" src="./assets/media/pages/our-services/overview/vuejs.svg"/>
                        </SplideSlide>
                        <SplideSlide>
                            <img className="_service-overview_app-development_tech" src="./assets/media/pages/our-services/overview/wordpress.svg"/>
                        </SplideSlide>
                        <SplideSlide>
                            <img className="_service-overview_app-development_tech" src="./assets/media/pages/our-services/overview/ruby-on-rails.svg"/>
                        </SplideSlide>
                        <SplideSlide>
                            <img className="_service-overview_app-development_tech" src="./assets/media/pages/our-services/overview/bitbucket.svg"/>
                        </SplideSlide>
                    </Splide>
                </div>
            </div>
            <div className="_service-overview _service-overview_ai section" id="aiml-section" ref={ref => sectionRefs.current["AI and ML"] = ref}>
                <div className="_service-overview_wrapper _service-overview_wrapper_ai _container">
                    <div className="_service-overview_info">
                        <h6 className="_service-overview_title _sub_h2 title">AI & ML</h6>
                        <p className="_service-overview_desc desc">
                            <span>
                            We leverage the transformative capabilities of Artificial Intelligence (AI) and Machine Learning (ML) to propel your business forward. Our advanced AI and ML solutions are crafted to enhance your operations, improve decision-making, and provide valuable insights.
                            </span>
                        </p>
                    </div>
                    <div className="_service-overview_info_desc desc">
                        <div className="_service-overview_media">
                            <ul className="_service-overview_proccess _service-overview_proccess_responsive">
                                <li className="_service-overview_proccess_item">
                                    Transformative Insights
                                </li>
                                <li className="_service-overview_proccess_item">
                                Enhanced Automation
                                </li>
                                <li className="_service-overview_proccess_item">
                                Personalized Experiences
                                </li>
                                <li className="_service-overview_proccess_item">
                                Predictive Analytics
                                </li>
                            </ul>
                            <img className="_service-overview_media_banner banner" src="./assets/media/pages/our-services/overview/AI.png"/>
                        </div>
                        <div className="_service-overview_info _service-overview_info_2">
                            <p className="_service-overview_desc desc">
                            <span>
                            We start by deeply understanding your unique business challenges and goals. Our team then designs and develops bespoke AI and ML models that address your specific needs. Whether it's predictive analytics, natural language processing, or automation, our solutions are tailored to optimize your performance.
                            </span>
                            <span>
                            Our focus is on delivering scalable and seamlessly integrated technology that enhances your existing systems. We offer continuous support and updates to ensure your AI and ML tools remain cutting-edge and effective.
                            </span>
                            <ul className="_service-overview_proccess">
                            <li className="_service-overview_proccess_item">
                                    Transformative Insights
                                </li>
                                <li className="_service-overview_proccess_item">
                                Enhanced Automation
                                </li>
                                <li className="_service-overview_proccess_item">
                                Personalized Experiences
                                </li>
                                <li className="_service-overview_proccess_item">
                                Predictive Analytics
                                </li>
                            </ul>
                            <span className="_service-overview_desc_footer ">
                                Choose Mindcres Technologies to unlock new potentials and drive innovation through sophisticated AI and ML solutions. Let’s transform your business with the latest in technology.
                            </span>
                            </p>
                        </div>

                    </div>
                </div>
            </div>
            <div className="_service-overview _service-overview_staff-augmentation section" id="staff-section" ref={ref => sectionRefs.current["Staff Augmentation"] = ref}>
                <div className="_service-overview_wrapper _service-overview_wrapper_3 _container">
                    <div className="_service-overview_info _service-overview_info_3">
                        <h6 className="_service-overview_title _sub_h2 title">Staff Augmentation</h6>
                        <p className="_service-overview_desc desc">
                            <span>
                            We offer tailored staff augmentation services to help you adapt and thrive in a dynamic business environment. Our solutions enable you to swiftly expand your team with experienced professionals who seamlessly integrate with your existing workforce.
                            </span>

                            <span>
                            We begin by assessing your project needs and organizational objectives. Whether you require additional expertise for a specific project or ongoing support, we connect you with top-tier talent that aligns with your technical and cultural requirements. Our diverse talent pool spans various industries, including software development, IT, marketing, and finance.
                            </span>

                            <span>
                                Our staff augmentation approach provides you with access to specialized skills without the long-term commitment of hiring full-time employees. This flexibility helps you respond quickly to changing market conditions, boost productivity, and achieve your project goals efficiently.
                            </span>
                        </p>
                        <div className="_service-overview_proccess desc">
                        Partner with us to enhance your team with the right talent and expertise to drive your success.


                        </div>
                        {/* <div className="_service-overview_proccess">
                            <span className="_service-overview_proccess_item">
                                <img src="./assets/media/pages/our-services/overview/search.png"/>
                                Discovery
                            </span>
                            <span className="_service-overview_proccess_item">
                                <img src="./assets/media/pages/our-services/overview/idea.png"/>
                                Ideation
                            </span>
                            <span className="_service-overview_proccess_item">
                                <img src="./assets/media/pages/our-services/overview/design.png"/>
                                Design
                            </span>
                            <span className="_service-overview_proccess_item">
                                <img src="./assets/media/pages/our-services/overview/deliver.png"/>
                                Deliver
                            </span>
                        </div> */}
                    </div>
                    <div className="_service-overview_media">
                        <div className="_service-overview_proccess _service-overview_proccess_responsive">
                            <span className="_service-overview_proccess_item">
                                <img src="./assets/media/pages/our-services/overview/search.png"/>
                                Discovery
                            </span>
                            <span className="_service-overview_proccess_item">
                                <img src="./assets/media/pages/our-services/overview/idea.png"/>
                                Ideation
                            </span>
                            <span className="_service-overview_proccess_item">
                                <img src="./assets/media/pages/our-services/overview/design.png"/>
                                Design
                            </span>
                            <span className="_service-overview_proccess_item">
                                <img src="./assets/media/pages/our-services/overview/deliver.png"/>
                                Deliver
                            </span>
                        </div>
                        <img className="_service-overview_media_banner banner" src="./assets/media/pages/our-services/overview/team.png"/>
                    </div>
                </div>
            </div>
        </>
    );
}
 
export default ServicesOverview;