import { useGSAP } from "@gsap/react";
import PortfolioWorkCard from "../PortfolioWorkCard";
import Footer from "../sections/Footer";

import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useEffect, useState } from "react";

const OurWork = () => {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        // Function to update window width
        const handleResize = () => {
          setWindowWidth(window.innerWidth);
        };
        
        handleResize();
        // Add event listener
        window.addEventListener('resize', handleResize);
    
        // Clean up event listener on component unmount
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);

    
    useGSAP(() => {
        const loadTl = gsap.timeline({ defaults: { opacity: 0, ease: 'ease-in', duration: 1 } });
    
        const pageFadeIn = () => {
          const tl = gsap.timeline();
          tl.from('body', { opacity: 0, ease: 'linear', duration: 1 });
          return tl;
        };
        loadTl.add(pageFadeIn());
    }, []);


    const workCardsData = [
        {
          id: 1,
          logoSrc:"./assets/media/pages/our-work/Snorequit.svg",
          typo: [
            "SnoreQuit offers a range of innovative snoring aid products designed to provide a restful night's sleep. Our solutions include nasal spray, mouthpieces, and earplugs, all crafted to reduce snoring and enhance comfort. With SnoreQuit, you can enjoy quiet, uninterrupted sleep and wake up refreshed. Say goodbye to snoring and hello to peaceful nights with SnoreQuit."
          ],
          mediaSrc: `${windowWidth > 1025 ? './assets/media/pages/our-work/Image1.png' : './assets/media/pages/our-work/Image.png'} `,
          keyPoints: [
            "Enhanced comfort",
            "Comfortable nights",
            "Better life"
          ]
        },
        {
            id: 2,
            logoSrc:"./assets/media/pages/our-work/NEXGEN.svg",
            typo: [
              "Nexgen Prop is your essential platform for exploring real estate projects with ease. Browse a curated selection of ongoing and completed projects, tailored to your preferences. With frequent project updates, expert insights, and educational resources on the market, Nexgen Prop venture capital team keeps you informed every step of the way. Make confident, well-informed decisions as you navigate the world of real estate with Nexgen Prop."            ],
            mediaSrc: "./assets/media/pages/our-work/Image3.png",
            keyPoints: [
                "Venture capital dashboard",
                "Investment tracking",
                "Expert advice"
              ]
        },
        {
            id: 3,
            logoSrc:"./assets/media/pages/our-work/Build360.svg",
            typo: [
                "Build360 is a comprehensive application designed to help users manage and track construction projects effortlessly. With real-time updates, task assignments, budget monitoring, and progress tracking, Build360 ensures seamless collaboration and project efficiency. Its user-friendly interface and robust features make it the ultimate tool for keeping construction projects on schedule and within budget."
            ],
            mediaSrc: "./assets/media/pages/our-work/Image2.png",
            keyPoints: [
                "Manage tasks",
                "Track progress",
                "Coordinate projects"
            ]
        },
    ];

    return (
        <>
            <section className="_portfolio">
                <div className="_portfolio_wrapper _container">
                    <div className="_portfolio_info">
                        <h1 className="_portfolio_title">MindCres Portfolio</h1>
                        <p>
                            Explore our portfolio to see how we've transformed businesses with our innovative and customized technological solutions. From software development to IT consulting, our projects showcase our commitment to excellence and our ability to meet the unique needs of our clients across various industries.
                        </p>
                    </div>
                </div>
                    <div className="_portfolio_work">
                        {
                            workCardsData && workCardsData.map((item, index) => (
                                <PortfolioWorkCard key={index} index={index} logo={item.logoSrc} keyPoints={item.keyPoints} typo={item.typo} mediaSrc={item.mediaSrc}/>
                            ))
                        }
                    </div>
                        <span className="_portfolio_work_stay_tuned">Stay tuned for further projects...</span>
            </section>
        </>
    );
}
 
export default OurWork;