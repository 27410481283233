// Default theme
import '@splidejs/react-splide/css';
import { Route, Routes } from "react-router-dom";
import Index from "./components/pages/Index";
import AboutUs from "./components/pages/AboutUs";
import OurWork from "./components/pages/OurWork";
import ContactUs from "./components/pages/ContactUs";
import Header from "./components/Header";
import Footer from "./components/sections/Footer";
import Career from "./components/pages/Career";
import OurServices from "./components/pages/OurServices";
import ScrollToTopOnRouteChange from "./components/utilities/ScrollToTopOnRouteChange";
import JobDetail from './components/pages/JobDetail';
import UpwardArrow from './components/utilities/UpwardArrow';

function App() {
  

  return (
    <>
      <Header/>
      <Routes>
        <Route path="/" element={<Index/>}/>
        <Route path="/about-us" element={<AboutUs/>}/>
        <Route path="/our-work" element={<OurWork/>}/>
        <Route path="/our-services" element={<OurServices/>}/>
        <Route path="/contact-us" element={<ContactUs/>}/>
        <Route path="/career" element={<Career/>}/>
        <Route path="/job-detail/:jobId" element={<JobDetail/>}/>
      </Routes>
      <Footer/>
      <UpwardArrow/>
      <ScrollToTopOnRouteChange/>
    </>
  );
}

export default App;
