const OurAims = () => {
    return (
        <>
            <section className="_our-aims">
                <div className="_our-aims_wrapper _container">
                    <div className="_our-vision">
                        <div className="_our-vision_info">
                            <h6 className="_our-vision_title _sub_h2">Our vision</h6>
                            <p className="_our-vision_typo">
                                <span>
                                We champion innovation by embracing cutting-edge technologies and driving digital transformation to keep you ahead in a rapidly evolving market. Our commitment to process excellence ensures that every project is executed with precision and efficiency. 
                                </span>
                                <span>
                                We value people, fostering a collaborative environment where our talented team and clients work together to achieve remarkable results. Integrity guides our interactions, ensuring transparency and trust in every partnership. 
                                </span>
                                <span>
                                These values drive us to deliver exceptional solutions that empower your business and exceed expectations.
                                </span>
                            </p>
                        </div>
                        <figure className="_our-vision_media_wrapper">
                            <img className="_our-vision_media" src="/assets/media/pages/about-us/our-aims/media.png"/>
                        </figure>
                    </div>
                    <div className="_our-values">
                        <div className="_our-values_info">
                            <h6 className="_our-values_title _sub_h2">Our Values</h6>
                            <p className="_our-values_typo">
                                <span>
                                    Our core values guide everything we do. We are deeply committed to maintaining the highest standards of ethics, ensuring that integrity, transparency, and respect are at the heart of our operations. We believe in fostering a supportive and collaborative environment for our people, where every team member’s contributions are valued and recognized. By prioritizing ethical practices and cultivating a positive workplace culture, we build strong, trust-based relationships with our clients and partners. 
                                </span>
                                <span>
                                    Our dedication to these values drives us to deliver exceptional results and ensures that we consistently meet and exceed the expectations of those we serve.
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
 
export default OurAims;