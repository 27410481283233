import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTopOnRouteChange = () => {
  const { pathname } = useLocation();

  // const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0); // Reset scroll position on route change
  }, [pathname]);

  // useEffect(() => {
  //   // checkUserToken();
    
  //   const body = document.querySelector('#root');
  //   body.scrollIntoView({
  //     behavior: 'smooth'
  //   }, 100)
  // }, [pathname]);

  return;
};

export default ScrollToTopOnRouteChange;
