import { useEffect, useState } from "react";
import LetsConnect from "../LetsConnect";
import ClientReview from "../sections/ClientReview";
import Footer from "../sections/Footer";
import OurAims from "../sections/OurAims";
import TechVisionStatement from "../sections/TechVisionStatement";
import BrandLogo from "../utilities/BrandLogo";

import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";

gsap.registerPlugin(ScrollTrigger);
const AboutUs = () => {

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
      // Function to update window width
      const handleResize = () => {
        setWindowWidth(window.innerWidth);
      };
      
      handleResize();
      // Add event listener
      window.addEventListener('resize', handleResize);
  
      // Clean up event listener on component unmount
      return () => {
        window.removeEventListener('resize', handleResize);
      };
  }, []);
    
  
  useGSAP(() => {
    const isMobile = window.matchMedia('(max-width: 768px)').matches;
    const isDesktop = window.matchMedia('(min-width: 769px)').matches;
    const loadTl = gsap.timeline({ defaults: { opacity: 0, ease: 'ease-in', duration: 1 } });
    
        const pageFadeIn = () => {
          const tl = gsap.timeline();
          tl.from('body', { opacity: 0, ease: 'linear', duration: 1 });
          return tl;
        };
    
        // const heroAnimation = () => {
        //   const tl = gsap.timeline({ defaults: { opacity: 0, ease: 'ease-in', duration: 1 } });
        //   tl.from('.tagline', { x: -20 })
        //     .from('.button', { y: -20 }, '<1');
        //   return tl;
        // };
    
        const aboutAnimation = () => {
          const tl = gsap.timeline({
            scrollTrigger: {
              trigger: '._about-us',
              start: '30% 75%',
              end: '60% 30%',
            //   toggleActions: 'restart reverse restart reverse',
            //   markers: true
            }
          }).from('._about-us', { y: 40, opacity: 0, stagger: 0.2 });
          return tl;
        };

        const ourValues = () => {
          const tl = gsap.timeline({
            scrollTrigger: {
              trigger: '._our-values',
              start:isDesktop ? '10% bottom' : '10% center',
              end: '60% top',
            //   toggleActions: 'restart reverse restart reverse',
            //   markers: true
            }
          }).from('._our-values', { y: 100, opacity: 0, duration: 0.3, stagger: 10.2 });
          return tl;
        };

        const ourVision = () => {
          const tl = gsap.timeline({
            scrollTrigger: {
              trigger: '._our-vision',
              start:isDesktop ? '10% bottom' : '10% center',
              end: '60% top',
            //   toggleActions: 'restart reverse restart reverse',
            //   markers: true
            }
          }).from('._our-vision', { y: 100, opacity: 0, duration: 0.3, stagger: 0.2 });
          return tl;
        };

        const Revolutionizing = () => {
          const tl = gsap.timeline({
            scrollTrigger: {
              trigger: '._tech-vision-statement',
              start:isDesktop ? '10% center' : 'top center',
              end: '60% top',
            //   toggleActions: 'restart reverse restart reverse',
              // markers: true
            }
          }).from('._tech-vision-statement', { opacity: 0, duration: 1, stagger: 0.2 });
          return tl;
        };
    
        loadTl.add(pageFadeIn());

        // const heroTl = heroAnimation();
        // heroTl.pause();
        // loadTl.add(heroTl.tweenFromTo(0, heroTl.duration()), '<1.5');
        // loadTl.add(() => {
        //   ScrollTrigger.create({
        //     animation: heroTl,
        //     trigger: '.hero',
        //     start: '30% 40%',
        //     end: '50% 20%',
        //     toggleActions: 'play reverse restart reverse',
        //     // markers: true
        //   });
        // });
    
        aboutAnimation();
        ourValues();
        ourVision();
        Revolutionizing();
    }, []);

    


    return (
        <>
            <section className="_about-us">
                <div className="_container _about-us_wrapper">
                    <h1 className="_h2 _about-us_title">
                        About Us
                    </h1>
                    <div className="_about-us_info">
                        <div className="_about-us_sub-title_wrapper _sub_h2">
                            <h3 className="_about-us_sub-title">Innovate the future with MindCres</h3>
                            {/* <span>With</span>
                            <BrandLogo/> */}
                        </div>
                        <div className="_about-us_typo_wrapper">
                            <p>
                            At Mindcres Technologies, our vision is to lead in innovation and empower businesses with cutting-edge technology and exceptional service. We specialize in UI/UX design, app development, web development, AI/ML solutions, and staff augmentation, upholding excellence and integrity in all we do. Guided by our core values of innovation, collaboration, and ethical practices, we deliver customized, impactful solutions. By supporting our team and prioritizing client satisfaction, we transform your vision into reality and drive your success. Partner with us for excellence.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <OurAims/>
            <TechVisionStatement/>
            <ClientReview/>
            <LetsConnect/>
        </>
    );
}
 
export default AboutUs;