import { useEffect, useLayoutEffect, useRef, useState } from "react";
import BrandLogo from "./utilities/BrandLogo";
import { Link, useLocation } from 'react-router-dom'
import Menu from "./utilities/burger-menu/Menu";
import Burger from "./utilities/burger-menu/Burger";
import useOnClickOutside from "./utilities/burger-menu/useOnClickOutside";


import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";


gsap.registerPlugin(ScrollTrigger);

const Header = () => {
    const { pathname } = useLocation();
    const [activePage, setActivePage] = useState();
  
    useLayoutEffect(() => {
        setActivePage(pathname)
    }, [pathname])


    // Humburger Menu
    const [open, setOpen] = useState(false);
    const node = useRef();
    useOnClickOutside(node, () => setOpen(false));

    // Width Body Calculate
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        // Function to update window width
        const handleResize = () => {
          setWindowWidth(window.innerWidth);
        };
        
        handleResize();
        // Add event listener
        window.addEventListener('resize', handleResize);
    
        // Clean up event listener on component unmount
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);



    // GSAP Scroll Trigger ======================================

    const headerRef = useRef(null);
    // useEffect(() => {
    //     const header = headerRef.current;

    //     ScrollTrigger.create({
    //         trigger: header,
    //         start: "top top",
    //         end: "bottom top",
    //         onEnter: () => header.classList.add('_navbar_scrolled'),
    //         onLeaveBack: () => header.classList.remove('_navbar_scrolled'),
    //     });

    //     return () => {
    //         ScrollTrigger.kill();
    //     };
    // }, []);

    return (
        <>
            <header className="_navbar" ref={headerRef}>
                <div className="_container_block _navbar_wrapper">
                    <div className="_navbar_brand">
                        <Link to="/">
                            <BrandLogo csName="_navbar_brand-main" fill="#005B4C" w={148} h={60}/>
                        </Link>
                    </div>
                        {
                            windowWidth > 1025 ?
                                <div className="_navbar_links">
                                    <div className="_navbar_link">
                                        <Link to="/about-us" className={`${activePage === '/about-us' ? '_navbar_link-active' : '' }`}>About</Link>
                                    </div>
                                    <div className="_navbar_link">
                                        <Link to="/our-services"  className={`${activePage === '/our-services' ? '_navbar_link-active' : '' }`}>Services</Link>
                                    </div>
                                    <div className="_navbar_link">
                                        <Link to="/our-work" className={`${activePage === '/our-work' ? '_navbar_link-active' : '' }`}>Our work</Link>
                                    </div>
                                    <div className="_navbar_link">
                                        <Link to="/career" className={`${activePage === '/career' ? '_navbar_link-active' : '' }`}>Careers</Link>
                                    </div>
                                    <Link to={"/contact-us"} className="_button _is_primary _is_medium _navbar_button">
                                        Contact us
                                    </Link>
                                </div>
                            :
                                <div ref={node} style={{position:'relative'}}>
                                    <Burger open={open} setOpen={setOpen} />
                                    <Menu open={open} setOpen={setOpen}/>
                                </div>
                        }
                    </div>
            </header>
        </>
    );
}
 
export default Header;