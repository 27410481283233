import ClientReviewCard from "../ClientReviewCard";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";



const ClientReview = () => {

    let reviews = [
        {
            name:"Punnarao K",
            des:'"The team delivered a seamless transition from design to development, with a level of quality few others achieve. Their work ethic and attention to detail are exceptional, and they included me in every step, ensuring the project aligned perfectly with my vision. The results exceeded my expectations."'
        },
        {
            name:"Michael A",
            des:'"Excellent front-end protocols and standards throughout the project. Great attention to detail and professionalism—keep up the good work!"'
        },
        {
            name: "Vinay Y",
            des: "\"The team worked with impressive dedication, delivering a top-notch, user-friendly design. It\'s one of the best app experiences I’ve seen, with outstanding attention to usability and aesthetics.\""
        }        
    ]
    return (
        <>
            <section className="_client-review">
                <div className="_client-review_wrapper _container">
                    <h6 className="_sub-h2 _client-review_title">What our client have to say......</h6>
                    <div className="_client-review_list">
                        {
                            reviews && reviews.map((review, index) => (
                                <>
                                    <ClientReviewCard name={review.name} review={review.des} key={index}/>
                                </>
                            ))
                        }
                    </div>
                    <div className="_client-review_list _client-review_list_responsive">
                        <Splide 
                            options={{
                                type: "loop",
                                gap: "20px",
                                drag: "free",
                                arrows: false,
                                focus: 'center',
                                pagination: false,
                                // autoplay:true,
                                interval     : 2500,
                                resetProgress: true,
                                breakpoints: {
                                    481: {
                                        perPage: 1,
                                    },
                                    660: {
                                        perPage: 1.5,
                                    },
                                    6400: {
                                        perPage: 2,
                                    },
                                },
                                autoScroll: {
                                    pauseOnHover: false,
                                    pauseOnFocus: false,
                                    rewind: true,
                                    speed: 0.8,
                                },
                            }}
                            extensions={{ AutoScroll }}
                        >
                            {
                            reviews && reviews.map((review, index) => (
                                <>
                                    <SplideSlide key={index}>
                                        <ClientReviewCard name={review.name} review={review.des}/>
                                    </SplideSlide>
                                </>
                            ))}
                        </Splide>
                    </div>
                </div>
            </section>        
        </>
    );
}
 
export default ClientReview;