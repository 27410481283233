import { useGSAP } from "@gsap/react";
import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useEffect, useState } from "react";

gsap.registerPlugin(ScrollTrigger);
ScrollTrigger.normalizeScroll(true);

const PortfolioWorkCard = ({ logo, typo, mediaSrc, keyPoints, index}) => {

    useGSAP(() => {
        ScrollTrigger.matchMedia({
            "(min-width: 1281px)": function() {
                // Select all elements with the class _portfolio-work-card_info
                const elements = document.querySelectorAll("._portfolio-work-card_info");
                const elementsMedia = document.querySelectorAll("._portfolio-work-card_media_wrapper");
    
                elements.forEach((element, index) => {
                    const isOdd = index % 2 == 0;
                    const xPercentStart = isOdd ? -50 : 50;
    
                    // Apply GSAP animation to each element
                    gsap.fromTo(element, {
                        xPercent: xPercentStart,
                        opacity: 0,
                    },{
                        xPercent: 0,
                        opacity: 1,
                        ease: "none",
                        scrollTrigger: {
                            trigger: element,
                            start: "40% bottom",
                            end: "+=100% +=60%",
                            // markers: true,
                            // scrub: 1
                        },
                    });
                });

                elementsMedia.forEach((element, index) => {
                    const isOdd = index % 2 !== 0;
                    const xPercentStart = isOdd ? -50 : 50;
    
                    // Apply GSAP animation to each element
                    gsap.fromTo(element, {
                        xPercent: xPercentStart,
                        opacity: 0,
                    },{
                        xPercent: 0,
                        opacity: 1,
                        ease: "none",
                        scrollTrigger: {
                            trigger: element,
                            start: "40% bottom",
                            end: "+=100% +=60%",
                            // markers: true,
                            // scrub: 1
                        },
                    });
                });
            }
        });
    });


    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        // Function to update window width
        const handleResize = () => {
          setWindowWidth(window.innerWidth);
        };
    
        // Add event listener
        window.addEventListener('resize', handleResize);
    
        // Clean up event listener on component unmount
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);
    

    return (
        <>
            <div className="_portfolio-work-card">
                <div className="_portfolio-work-card_info _container">
                    <div className="_portfolio-work-card_info_media">
                        <img className={`_portfolio-work-card_logo _portfolio-work-card_logo_${index + 1}`} src={logo}/>
                        {
                            windowWidth > 1024 ?
                                null
                                :
                                <div className="_portfolio-work-card_media_wrapper _portfolio-work-card_media_wrapper_responsive">
                                    <img src={mediaSrc} className="_portfolio-work-card_media"/>
                                </div>
                        }
                    </div>
                    <p className="_portfolio-work-card_typo">
                        {typo.map((text, index) => (
                            <span key={index}>{text}</span>
                        ))}
                    </p>
                    {
                        keyPoints ? 
                            <ul className={`_portfolio-work-card_keys _portfolio-work-card_keys_${index + 1} `}>
                                {
                                    keyPoints && keyPoints.map((item, index) => (
                                        <li className={`_portfolio-work-card_key _portfolio-work-card_key_${index + 1}`} key={index}>{item}</li>
                                    ))
                                }
                            </ul>
                            :
                            null
                    }
                </div>
                {
                            windowWidth > 1024 ?
                            <div className="_portfolio-work-card_media_wrapper">
                    <img src={mediaSrc} className="_portfolio-work-card_media"/>
                </div>
                    :
                    null
                }

            </div>
        </>
    );
}
 
export default PortfolioWorkCard;