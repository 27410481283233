import React, { useEffect, useState } from 'react';
import BrandLogo from '../utilities/BrandLogo';


const FutureInnovation = () => {

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
      // Function to update window width
      const handleResize = () => {
        setWindowWidth(window.innerWidth);
      };
  
      // Add event listener
      window.addEventListener('resize', handleResize);
  
      // Clean up event listener on component unmount
      return () => {
        window.removeEventListener('resize', handleResize);
      };
  }, []);
  

  return (
    <>
        <section className='_future-innovation'>
            <div className='_future-innovation_wrapper _container'>
                <div className='_future-innovation_info_wrapper'>
                  <div className='_future-innovation_info'>
                    <h2 className='_future-innovation_title'>Innovate the Future</h2>
                    <span className='_future-innovation_tag'>
                      <span>With</span>
                      <span><BrandLogo brandColor="#000"/></span>
                    </span>
                  </div>
                  <div className='_future-innovation_media_wrapper'>
                    <div className='_future-innovation_media'>
                      <img src='./assets/media/pages/index/future-innovation/iphone2.png'/>
                      <img src='./assets/media/pages/index/future-innovation/iphone.png'/>
                      <img src='./assets/media/pages/index/future-innovation/iphone3.png'/>
                      <img className='_future-innovation_media_grediant' src="./assets/media/pages/index/future-innovation/gradiant.png"/>
                    </div>
                  </div>
                </div>
            </div>
        </section>
    </>
  );
};

export default FutureInnovation;
