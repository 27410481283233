import { useGSAP } from "@gsap/react";
import GetInTouch from "../sections/GetInTouch";

import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useEffect, useState } from "react";


const ContactUs = () => {
  
    gsap.registerPlugin(ScrollTrigger);
    
    useGSAP(() => {
        const loadTl = gsap.timeline({ defaults: { opacity: 0, ease: 'ease-in', duration: 1 } });
    
        const pageFadeIn = () => {
          const tl = gsap.timeline();
          tl.from('body', { opacity: 0, ease: 'linear', duration: 1 });
          return tl;
        };
        loadTl.add(pageFadeIn());
    }, []);
    

    // if (!configValues) {
    // return <div>Loading...</div>;
    // }
    

    return (
        <>
            <section className="_contact-us">
                <h1 className="_contact-us_title">
                    Let's make your ideas real, together
                </h1>
                <GetInTouch />
            </section>
        </>
    );
}
 
export default ContactUs;