import { Link } from "react-router-dom";

const Hero = ({title, desc, btn, page, path}) => {
    return (
        <>
            <section className={`_hero ${page ? page : ''}`}>
                <div className="_hero_wrapper">
                    <div className="_hero_banner_wrapper">
                        {
                            page ?
                            <img className="_hero_banner" src={`./assets/media/pages/${page}/hero/banner.jpeg`}/>
                            :
                            <img className="_hero_banner" src="./assets/media/pages/index/hero/banner.jpeg"/>
                        }
                    </div>
                    <div className="_hero_info_wrapper _container">
                        <div className="_hero_info">
                            <h1 className="_hero_title _h1">
                                {
                                    title ?
                                    title
                                    :
                                    // "Welcome to Mindcres Technologies"
                                    <>Welcome to <span>Mindcres Technologies</span></>
                                }
                            </h1>
                            {
                                desc ?
                                <p className="_hero_typo">{desc}</p>
                                :
                                null
                            }
                        </div>
                        {
                            btn ?
                            <Link to={path ? path : ''} className="_button _is_primary _is_medium _hero_button">
                                {btn} 
                            </Link>
                            :
                            null
                        }
                    </div>
                </div>
            </section>
        </>
    );
}
 
export default Hero;