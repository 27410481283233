const ServiceCard = ({title, scrollToSection, logo, desc}) => {
    return (
        <>
            <div className="_service-card" onClick={() => scrollToSection(title)}>
                <div className="_service-card_bg_wrapper">
                    <img className="_service-card_bg" src="./assets/media/service-card/bg.png"/>
                </div>
                <figure className="_service-card_media_wrapper">
                    <img className="_service-card_media" src={`./assets/media/service-card/${logo}.svg`}/>
                </figure>
                <div className="_service-card_info">
                    <h6 className="_service-card_info_title">{title ? title : "UI/UX Design"}</h6>
                    <p className="_service-card_info_typo">
                        {desc}
                    </p>
                </div>
                <div className="_service-card_button_wrapper">
                    <button className="_service-card_button _button _is_primary _is_full" onClick={() => scrollToSection(title)}>
                    Know more
                    </button>
                </div>
            </div>
        </>
    );
}
 
export default ServiceCard;