const TechVisionStatement = () => {
    return (
        <>
            <section className="_tech-vision-statement">
                <div className="_container">
                    <span className="_tech-vision-statement_col_left">
                        <svg xmlns="http://www.w3.org/2000/svg" width="73" height="51" viewBox="0 0 73 51" fill="none">
                            <path d="M40.903 31.0662C40.903 25.8125 41.7113 21.2997 43.3278 17.5278C44.9443 13.6212 47.0323 10.4555 49.5918 8.03072C52.1513 5.47122 55.0476 3.65264 58.2806 2.57495C61.6484 1.36256 64.9488 0.756367 68.1818 0.756367V7.82865C64.6794 7.82865 61.379 9.04105 58.2806 11.4658C55.317 13.7559 53.5658 16.9216 53.0269 20.9629C53.4311 20.8282 53.9025 20.6935 54.4414 20.5588C54.8455 20.424 55.317 20.2893 55.8558 20.1546C56.5294 20.0199 57.2703 19.9526 58.0786 19.9526C62.1199 19.9526 65.4876 21.5017 68.1818 24.6001C70.876 27.5637 72.2231 31.0661 72.2231 35.1075C72.2231 39.1488 70.8087 42.7186 67.9798 45.8169C65.2856 48.7805 61.6484 50.2623 57.0682 50.2623C51.9492 50.2623 47.9753 48.3764 45.1464 44.6045C42.3175 40.6979 40.903 36.1851 40.903 31.0662ZM0.489978 31.0662C0.489978 25.8125 1.29824 21.2997 2.91476 17.5278C4.53128 13.6212 6.61929 10.4555 9.17878 8.03072C11.7383 5.47122 14.6345 3.65264 17.8676 2.57495C21.2353 1.36256 24.5357 0.756367 27.7688 0.756367V7.82865C24.2663 7.82865 20.9659 9.04105 17.8676 11.4658C14.904 13.7559 13.1527 16.9216 12.6139 20.9629C13.018 20.8282 13.4895 20.6935 14.0283 20.5588C14.4325 20.424 14.904 20.2893 15.4428 20.1546C16.1164 20.0199 16.8573 19.9526 17.6655 19.9526C21.7068 19.9526 25.0746 21.5017 27.7688 24.6001C30.463 27.5637 31.8101 31.0661 31.8101 35.1075C31.8101 39.1488 30.3956 42.7186 27.5667 45.8169C24.8725 48.7805 21.2353 50.2623 16.6552 50.2623C11.5362 50.2623 7.56226 48.3764 4.73335 44.6045C1.90443 40.6979 0.489978 36.1851 0.489978 31.0662Z" fill="#FDC103"/>
                        </svg>
                    </span>
                </div>
                <div className="_tech-vision-statement_title_wrapper">
                    <div className="_container">
                        <h2 className="_tech-vision-statement_title">
                            Revolutionizing the future through intelligent and user-friendly technology
                        </h2>
                    </div>
                </div>
                <div className="_container">
                    <span className="_tech-vision-statement_col_right">
                        <svg xmlns="http://www.w3.org/2000/svg" width="73" height="51" viewBox="0 0 73 51" fill="none">
                            <path d="M32.097 31.0662C32.097 25.8125 31.2887 21.2997 29.6722 17.5278C28.0557 13.6212 25.9677 10.4555 23.4082 8.03072C20.8487 5.47122 17.9524 3.65264 14.7194 2.57495C11.3516 1.36256 8.05122 0.756367 4.81818 0.756367V7.82865C8.32064 7.82865 11.621 9.04105 14.7194 11.4658C17.683 13.7559 19.4342 16.9216 19.9731 20.9629C19.5689 20.8282 19.0975 20.6935 18.5586 20.5588C18.1545 20.424 17.683 20.2893 17.1442 20.1546C16.4706 20.0199 15.7297 19.9526 14.9214 19.9526C10.8801 19.9526 7.51238 21.5017 4.81818 24.6001C2.12398 27.5637 0.776875 31.0661 0.776875 35.1075C0.776875 39.1488 2.19133 42.7186 5.02025 45.8169C7.71445 48.7805 11.3516 50.2623 15.9318 50.2623C21.0508 50.2623 25.0247 48.3764 27.8536 44.6045C30.6825 40.6979 32.097 36.1851 32.097 31.0662ZM72.51 31.0662C72.51 25.8125 71.7018 21.2997 70.0852 17.5278C68.4687 13.6212 66.3807 10.4555 63.8212 8.03072C61.2617 5.47122 58.3655 3.65264 55.1324 2.57495C51.7647 1.36256 48.4643 0.756367 45.2312 0.756367V7.82865C48.7337 7.82865 52.0341 9.04105 55.1324 11.4658C58.096 13.7559 59.8473 16.9216 60.3861 20.9629C59.982 20.8282 59.5105 20.6935 58.9717 20.5588C58.5675 20.424 58.096 20.2893 57.5572 20.1546C56.8836 20.0199 56.1427 19.9526 55.3345 19.9526C51.2932 19.9526 47.9254 21.5017 45.2312 24.6001C42.537 27.5637 41.1899 31.0661 41.1899 35.1075C41.1899 39.1488 42.6044 42.7186 45.4333 45.8169C48.1275 48.7805 51.7647 50.2623 56.3448 50.2623C61.4638 50.2623 65.4377 48.3764 68.2667 44.6045C71.0956 40.6979 72.51 36.1851 72.51 31.0662Z" fill="#FDC103"/>
                        </svg>
                    </span>
                </div>
            </section>
        </>
    );
}
 
export default TechVisionStatement;