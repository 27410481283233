import { useEffect, useRef, useState } from "react";
import emailjs from 'emailjs-com';

import { fetchAndActivate, getString, remoteConfig } from "../utilities/firebaseConfig";
import { Link } from "react-router-dom";

const GetInTouch = () => {

    const [configValues, setConfigValues] = useState(null);

    useEffect(() => {
        const fetchConfig = async () => {
            try {
                await fetchAndActivate(remoteConfig);
                const value1 = await getString(remoteConfig, 'mindcresContent');
                const jsonObject = JSON.parse(value1); // Parse the JSON string into an object
    
                const formattedJson = JSON.stringify(jsonObject, null, 2); // Format the JSON object as a string with indentation
    
                // Print formatted JSON and access the "header" property
                // console.log(formattedJson);
                // console.log(jsonObject); // Access the "header" property of the parsed object
    
                setConfigValues(jsonObject?.contactUs?.rightSide ); // Store the parsed object in the state
            } catch (error) {
                console.error('Error fetching Remote Config values:', error);
            }
        };
    
        fetchConfig();
    }, []);


    const [details, setFormData] = useState({
        name: '',
        from_name: '',
        message: ''
    });

    const [errors, setErrors] = useState({
        from_name: ''
    });

    const form = useRef();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...details,
            [name]: value
        });

        // Clear email error when the user starts typing
        if (name === 'from_name') {
            setErrors({
                ...errors,
                from_name: ''
            });
        }
    };

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };


    const SERVICE_ID = "service_m6su6sk";
    // const SERVICE_ID = "service_ue2vypj";
    const TEMPLATE_ID = "template_d13jrbj";
    // const TEMPLATE_ID = "template_lpi6rer";
    const PUBLIC_KEY = "Q9zOafdP6lARmFSt9";
    // const PUBLIC_KEY = "FGPT6kgkOGtLZOph9";

    const sendEmail = (e) => {
        e.preventDefault();

        if (!validateEmail(details.from_name)) {
            setErrors({
                ...errors,
                from_name: 'Please enter a valid email address.'
            })
            alert("Please fill form")
            return;
        }

        emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, form.current, PUBLIC_KEY)
          .then((result) => {
            console.log(result.text);
            setFormData({
                name: '',
                from_name: '',
                message: ''
            })
            alert('Message Sent Successfully')
          }, (error) => {
            console.log(error.text);
            alert('Something went wrong!')
        });
        e.target.reset()
    }






    return (
        <>
            <section className="_get_in_touch">
                <h2 className="_get_in_touch_title_main _sub_h2">Get in touch</h2>
                <div className="_get_in_touch_wrapper _container">
                    <div className="_get_in_touch_form_wrapper">
                        <p className="_get_in_touch_title">
                            Contact us today to discuss your digital transformation goals and let us help you grow your business
                        </p>
                        <form className="_form_wrapper" ref={form} onSubmit={sendEmail}>
                            <div className="_input_group">
                                <input
                                    placeholder="Name"
                                    className="_input_field"
                                    type="text"
                                    id="name"
                                    name="name"
                                    value={details.name}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="_input_group">
                                <input
                                    placeholder="Email Address"
                                    className="_input_field"
                                    type="email"
                                    id="email"
                                    name="from_name"
                                    value={details.email}
                                    onChange={handleChange}
                                />
                            {errors.email && <p className="_input_error">{errors.email}</p>}
                            </div>
                            <div className="_input_group">
                                <textarea
                                    placeholder="Message"
                                    className="_input_field"
                                    id="message"
                                    name="message"
                                    value={details.message}
                                    onChange={handleChange}
                                />
                            </div>
                            <button className="_button _is_primary _is_medium _get_in_touch_form_button">Submit</button>
                        </form>
                    </div>
                    {
                        configValues ?
                        <div className="_get_in_touch_communication_wrapper">
                            <div className="_get_in_touch_communication_item">
                                <h6 className="_get_in_touch_communication_item_title">Visit <span>Us</span></h6>
                                <span className="_get_in_touch_communication_item_detail _get_in_touch_communication_item_detail_wrapper">
                                    {/* {configValues.visit.address} */}
                                    <span>
                                        4-91/1, Penamaluru, Vijayawada,
                                    </span>
                                    <span className="_get_in_touch_communication_item_detail_address">
                                        Krishna district, Pin code 521137 
                                    </span>
                                    <span className="_get_in_touch_communication_item_detail_address">
                                        Andhra Pradesh, India.
                                    </span>
                                </span>
                            </div>
                            <div className="_get_in_touch_communication_item">
                                <h6 className="_get_in_touch_communication_item_title">Email <span>Us</span></h6>
                                <a className="_get_in_touch_communication_item_detail">
                                {/* {configValues.email.address} */}
                                <Link className="_get_in_touch_communication_item_detail" to="mailto:hr@mindcres.com">
                                    hr@mindcres.com
                                </Link>
                                </a>
                            </div>
                            <div className="_get_in_touch_communication_item">
                                <h6 className="_get_in_touch_communication_item_title">Call <span>Us</span></h6>
                                <a className="_get_in_touch_communication_item_detail">
                                {/* {configValues.call.phoneNumber} */}
                                <Link className="_get_in_touch_communication_item_detail" to="tel:+917901054999">
                                        + 91 7901054999
                                        </Link>
                                </a>
                            </div>
                            <div className="_get_in_touch_communication_item">
                                <h6 className="_get_in_touch_communication_item_title">Follow <span>Us</span></h6>
                                <a href={configValues?.follow?.socialMedia?.url} target="_blank" className="_get_in_touch_communication_item_detail">
                                    <img src="./assets/media/pages/index/get-in-touch/linkedin.png"/>
                                    {configValues?.follow?.socialMedia.platform}
                                </a>
                            </div>
                        </div>
                        :
                        <div className="_get_in_touch_communication_wrapper">
                        <div className="_get_in_touch_communication_item">
                            <h6 className="_get_in_touch_communication_item_title">Visit <span>Us</span></h6>
                            <span className="_get_in_touch_communication_item_detail">
                            4-91/1, Penamaluru, Vijayawada,
                            Krishna district, Pin code 521137 
                            Andhra Pradesh, India.
                            </span>
                        </div>
                        <div className="_get_in_touch_communication_item">
                            <h6 className="_get_in_touch_communication_item_title">Email <span>Us</span></h6>
                            <a href="mailto:Sample@email.com" className="_get_in_touch_communication_item_detail">
                                Sample@email.com
                            </a>
                        </div>
                        <div className="_get_in_touch_communication_item">
                            <h6 className="_get_in_touch_communication_item_title">Call <span>Us</span></h6>
                            <a className="_get_in_touch_communication_item_detail">
                                + 91 7901054999
                            </a>
                        </div>
                        <div className="_get_in_touch_communication_item">
                            <h6 className="_get_in_touch_communication_item_title">Follow <span>Us</span></h6>
                            <a className="_get_in_touch_communication_item_detail">
                                <img src="./assets/media/pages/index/get-in-touch/linkedin.png"/>
                                Linkedin
                            </a>
                        </div>
                    </div>
                    }
                </div>
            </section>
        </>
    );
}
 
export default GetInTouch;