import JoinUsWay from "./JoinUsWay";

const JobForm = () => {
    return (
        <>
            {/* <section className="_job-form_overview _job-form_container">
                <div className="_job-form_wrapper">
                    <div className="_job-form_info">
                        <h2 className="_job-form_title _sub-h2">Join MindCres</h2>
                        <a class="_button _is_primary _is_medium _job-form_info_button" href="/contact-us">Upload resume</a>
                    </div>
                </div>
                <form className="_job-form">
                    <div className="_job-form_input_group">
                        <p className="_job-form_input_group_title">Personal information</p>
                        <div className="_job-form_input_group_items">
                            <div className="_job-form_input_group_row">
                                <div className="_job-form_input_group_item">
                                    <input
                                        type="text"
                                        placeholder="Name"
                                        className="_job-form_input"
                                    />
                                </div>
                                <div className="_job-form_input_group_item">
                                    <input
                                        type="email"
                                        placeholder="Email"
                                        className="_job-form_input"
                                    />
                                </div>
                            </div>
                            <div className="_job-form_input_group_row">
                                <div className="_job-form_input_group_item">
                                    <input
                                        type="text"
                                        placeholder="Phone Number"
                                        className="_job-form_input"
                                    />
                                </div>
                                <div className="_job-form_input_group_item">
                                    <input
                                        type="text"
                                        placeholder="Experience in years"
                                        className="_job-form_input"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="_job-form_input_group">
                        <p className="_job-form_input_group_title">Professional background</p>
                        <div className="_job-form_input_group_items">
                            <div className="_job-form_input_group_row">
                                <div className="_job-form_input_group_item">
                                    <input
                                        type="text"
                                        placeholder="Current Company name"
                                        className="_job-form_input"
                                    />
                                </div>
                                <div className="_job-form_input_group_item">
                                    <input
                                        type="text"
                                        placeholder="Your role"
                                        className="_job-form_input"
                                    />
                                </div>
                                <div className="_job-form_input_group_item">
                                    <input
                                        type="text"
                                        placeholder="Duration of employment"
                                        className="_job-form_input"
                                    />
                                </div>
                            </div>
                            <div className="_job-form_input_group_row">
                                <div className="_job-form_input_group_item">
                                    <input
                                        type="text"
                                        placeholder="Last Company name"
                                        className="_job-form_input"
                                    />
                                </div>
                                <div className="_job-form_input_group_item">
                                    <input
                                        type="text"
                                        placeholder="Your role"
                                        className="_job-form_input"
                                    />
                                </div>
                                <div className="_job-form_input_group_item">
                                    <input
                                        type="text"
                                        placeholder="Duration of employment"
                                        className="_job-form_input"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="_job-form_input_group">
                        <p className="_job-form_input_group_title">Personal information</p>
                        <div className="_job-form_input_group_items">
                            <div className="_job-form_input_group_row">
                                <div className="_job-form_input_group_item">
                                    <input
                                        type="text"
                                        placeholder="Role (Auto populated)"
                                        className="_job-form_input"
                                    />
                                </div>
                                <div className="_job-form_input_group_item">
                                    <input
                                        type="email"
                                        placeholder="When can you join"
                                        className="_job-form_input"
                                    />
                                </div>
                            </div>
                            <div className="_job-form_input_group_row">
                                <div className="_job-form_input_group_item">
                                    <textarea
                                        type="text"
                                        placeholder="Brief note"
                                        className="_job-form_input"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <a class="_button _is_primary _is_medium _job-form_button" href="/contact-us">Submit Application</a>
                </form>
            </section> */}
            <JoinUsWay/>
        </>
    );
}
 
export default JobForm;