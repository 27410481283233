import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const OurServices = () => {

        // Width Body Calculate
        const [windowWidth, setWindowWidth] = useState(window.innerWidth);

        useEffect(() => {
            // Function to update window width
            const handleResize = () => {
              setWindowWidth(window.innerWidth);
            };
            
            handleResize();
            // Add event listener
            window.addEventListener('resize', handleResize);
        
            // Clean up event listener on component unmount
            return () => {
              window.removeEventListener('resize', handleResize);
            };
        }, []);
    
    return (
        <>
            <section className="_our-services">
                <h2 className="_about-us_section_title _our-services_section_title _sub_h2">
                    Our <span className="_title_gradient">Services</span>
                </h2>
                <div className="_our-services_wrapper _container">
                    <div className="_our-services_grid">
                        <div className="_our-services_grid_item">
                            <div className="_our-services_grid_item_media">
                                <img src="./assets/media/pages/index/our-services/UX.png" alt />
                            </div>
                            <div className="_our-services_grid_item_typo _light-color">
                                UIUX design
                            </div>
                            <Link to="/our-services?section=uiux" className="_our-services_grid_item_link"/>
                        </div>
                        <div className="_our-services_grid_item">
                            <div className="_our-services_grid_item_media">
                                <img src="./assets/media/pages/index/our-services/app-dev.png" alt />
                            </div>
                            <div className="_our-services_grid_item_typo _light-color">
                                Application development
                            </div>
                            <Link to="/our-services?section=appdev" className="_our-services_grid_item_link"/>
                        </div>
                        <div className="_our-services_grid_item">
                            <div className="_our-services_grid_item_media">
                                <img src="./assets/media/pages/index/our-services/web-dev.png" alt />
                            </div>
                            <div className="_our-services_grid_item_typo _light-color">
                                Web development
                            </div>
                            <Link className="_our-services_grid_item_link" to="/our-services?section=webdev"/>
                        </div>
                        <div className="_our-services_grid_item">
                            <div className="_our-services_grid_item_media">
                                <img src="./assets/media/pages/index/our-services/al-ml.png" alt />
                            </div>
                            <div className="_our-services_grid_item_typo _light-color">
                                AI & ML
                            </div>
                            <Link className="_our-services_grid_item_link" to="/our-services?section=aiml"/>
                        </div>
                        <div className="_our-services_grid_item">
                            <div className="_our-services_grid_item_media">
                                <img src="./assets/media/pages/index/our-services/staff-augmentation.png" alt />
                            </div>
                            <div className="_our-services_grid_item_typo _light-color">
                                Staff Augmentation
                            </div>
                            <Link className="_our-services_grid_item_link" to="/our-services?section=staff"/>
                        </div>
                            {
                                windowWidth > 481 ?
                            
                        <div className="_our-services_grid_item">
                            <div className="_our-services_grid_item_media">
                                <img src="./assets/media/pages/index/our-services/learn-more.png" alt />
                            </div>
                            <Link className="_our-services_grid_item_link" to="/our-services"/>

                        </div>
                        :
                            <Link to="/our-services" className="_button _is_primary _is_medium _hero_button">
                                Learn More
                            </Link>
                            }
                    </div>
                </div>
            </section>
        </>
    );
}
 
export default OurServices;