import ServiceCard from "../utilities/ServiceCard";

import { useGSAP } from '@gsap/react';
import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);
ScrollTrigger.normalizeScroll(true);

const WhatWeCanDo = ({scrollToSection}) => {

    useGSAP(() => {
        ScrollTrigger.matchMedia({
            "(min-width: 1281px)": function() {

                const sections = document.querySelectorAll('.section'); // Assuming each section has the class 'section'

                // Media Scale for _future-innovation section 
                gsap.fromTo("._service-card", {
                    opacity: 0,
                    yPercent: 50
                }, {
                    opacity: 1,
                    yPercent: 0, // Adjust this value as needed
                    ease: "none",
                    duration:0.2,
                    scrollTrigger: {
                        // markers: "isDevelopment",
                        trigger: "._hero",
                        start: "75% top",
                        // scrub:true,
                        // end: "+=100% center",
                        // toggleActions: "restart pause reverse pause"
                    },
                    stagger: {
                        each: 0.1, // 5-second delay between each card
                        from: "start" // You can adjust the stagger direction if needed
                    }
                });

                gsap.fromTo("._what-we-can-do_section_title", {
                    opacity: 0,
                    yPercent: 50
                }, {
                    opacity: 1,
                    yPercent: 0, // Adjust this value as needed
                    ease: "none",
                    duration:0.4,
                    scrollTrigger: {
                        // markers: "isDevelopment",
                        trigger: "._hero",
                        start: "75% 10%",
                        // scrub:true,
                        // end: "+=10% center",
                    }
                });

                gsap.fromTo("._overview", {
                    opacity: 0,
                    yPercent: 50
                }, {
                    opacity: 1,
                    yPercent: 0, // Adjust this value as needed
                    ease: "none",
                    duration:0.5,
                    scrollTrigger: {
                        // markers: "isDevelopment",
                        trigger: "._what-we-can-do",
                        start: "bottom center",
                        // scrub:true,
                        end: "+=10% center",
                    }
                });

                sections.forEach((section) => {
                    const title = section.querySelector('.title');
                    const desc = section.querySelectorAll('.desc');
                    const banner = section.querySelector('.banner');
                    
                    if (title) {
                        gsap.fromTo(title, {
                            opacity: 0,
                            yPercent: 50
                        }, {
                            opacity: 1,
                            yPercent: 0,
                            ease: "none",
                            duration: 0.5,
                            scrollTrigger: {
                                trigger: section,
                                start: "top 50%", // Start when the top of the section reaches 50% of the viewport
                                end: "bottom center",
                            }
                        });
                    }

                    if (desc) {
                        desc.forEach((des) => {
                            gsap.fromTo(des, {
                                opacity: 0,
                                yPercent: 50
                            }, {
                                opacity: 1,
                                yPercent: 0,
                                ease: "slow",
                                duration: 0.5,
                                scrollTrigger: {
                                    trigger: section,
                                    start: "top 50%", // Start when the top of the section reaches 50% of the viewport
                                    end: "bottom center",
                                }
                            });
                        })
                    }
    
                    if (banner) {
                        gsap.fromTo(banner, {
                            opacity: 0
                        }, {
                            opacity: 1,
                            ease: "slow",
                            duration: 1,
                            // delay: 1,
                            scrollTrigger: {
                                trigger: section,
                                start: "top 50%", // Start when the top of the section reaches 50% of the viewport
                                end: "bottom center",
                            }
                        });
                    }
                });

                // gsap.fromTo("._service-overview_title", {
                //     opacity: 0,
                //     yPercent: 50
                // }, {
                //     opacity: 1,
                //     yPercent: 0, // Adjust this value as needed
                //     ease: "none",
                //     duration:0.5,
                //     scrollTrigger: {
                //         // markers: "isDevelopment",
                //         trigger: "._overview",
                //         start: "bottom center",
                //         // scrub:true,
                //         end: "+=1000% center",
                //     }
                // });

                // gsap.fromTo("._service-overview_desc", {
                //     opacity: 0,
                //     yPercent: 50
                // }, {
                //     opacity: 1,
                //     yPercent: 0, // Adjust this value as needed
                //     ease: "slow",
                //     duration:1,
                //     scrollTrigger: {
                //         // markers: "isDevelopment",
                //         trigger: "._overview",
                //         start: "bottom center",
                //         // scrub:true,
                //         end: "+=200% center",
                //     }
                // });

                // gsap.fromTo("._service-overview_media_banner", {
                //     opacity: 0
                // }, {
                //     opacity: 1,
                //     ease: "slow",
                //     duration:5,
                //     delay:1,
                //     scrollTrigger: {
                //         // markers: "isDevelopment",
                //         trigger: "._overview",
                //         start: "bottom center",
                //         // scrub:true,
                //         end: "+=200% center",
                //     }
                // });
            }
        });
    });
    


    return (
        <>
            <section className="_what-we-can-do">
                <div className="_container _what-we-can-do_wrapper">
                    <div className="_what-we-can-do_info">
                        <h2 className="_what-we-can-do_title _sub_h2">What We Can Do For You</h2>
                        <h2 className="_what-we-can-do_section_title_responsive _what-we-can-do_section_title">Our Services</h2>
                    </div>
                    <div className="_what-we-can-do_section">
                        <ServiceCard title="UI/UX Design" logo="logo-ui" desc="We meticulously design app flows and pages to enhance the intended user experience. Leveraging hundreds of best practices in usability (UX) and aesthetics (UI), we apply our expertise to websites, e-commerce platforms, mobile apps, and enterprise designs." scrollToSection={scrollToSection}/>
                        <h2 className="_what-we-can-do_section_title">Our Services</h2>
                        <ServiceCard title="Application Development" desc="We are a team of expert and passionate mobile developers who can help you turn your idea into a reality. We offer a range of iOS and Android mobile development services." logo="logo-app" scrollToSection={scrollToSection}/>
                        <ServiceCard title="Web development" logo="logo-web" desc="We specialize in creative and effective web sites and web applications development for businesses of all sizes. We offer custom web design & development, E-commerce, CMS and SEO-friendly web development." scrollToSection={scrollToSection}/>
                        <ServiceCard title="AI and ML" logo="logo-ai" desc="Our AI and ML services harness’ cutting-edge technology to deliver innovative solutions for your business. From predictive analytics and automation to personalized recommendations." scrollToSection={scrollToSection}/>
                        <ServiceCard title="Staff Augmentation" logo="logo-staff" desc="You can choose from a wide pool of talent across various domains and technologies, and hire them for as long as you need. You can Save time, money on recruitment, training, and infrastructure. " scrollToSection={scrollToSection}/>
                    </div>
                </div>
            </section>
        </>
    );
}
 
export default WhatWeCanDo;