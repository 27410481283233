const Burger = ({ open, setOpen }) => {
    return (
      <>
        <button className={`burger ${open ? 'open' : ''}`} onClick={() => setOpen(!open)}>
          <div />
          <div />
          <div />
        </button>
      </>
    );
  };

  export default Burger;