import ServicesOverview from "./ServicesOverview";
import {useState, useEffect, useRef} from "react"
import WhatWeCanDo from "./WhatWeCanDo";

const Overview = ({scrollToSection, sectionRefs}) => {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        // Function to update window width
        const handleResize = () => {
          setWindowWidth(window.innerWidth);
        };
    
        // Add event listener
        window.addEventListener('resize', handleResize);
    
        // Clean up event listener on component unmount
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);


    return (
        <>
            <section className="_overview">
                <div className="_overview_wrapper _container">
                    <figure className="_overview_media_wrapper">
                    
                        <img className="_overview_media" src="./assets/media/pages/our-services/overview/media.jpeg"/>
                    
                    </figure>
                    <div className="_overview_info">
                        <p className="_overview_title">
                            Overview
                            <figure className="_overview_media_wrapper _overview_media_wrapper_responsive">

                        <img className="_overview_media" src="./assets/media/pages/our-services/overview/media-res.png"/>

                            </figure>
                        </p>
                        <p className="_overview_typo">
                            <span>
                            At Mindcres Technologies, we provide a comprehensive range of services to enhance your business. Our expertise includes UI/UX design, where we craft intuitive, user-friendly interfaces, creating high-performance native Android, iOS, or hybrid mobile apps, delivering responsive and high-performance websites; and AI/ML solutions, utilizing advanced technology to streamline and optimize your operations. Additionally, our staff augmentation services allow you to expand your team with top talent, tailored to your specific needs. 
                            </span>
                            <span>
                            Each service is delivered with a commitment to innovation, excellence, and client satisfaction. Partner with us to transform your ideas into impactful results and achieve your business goals.
                            </span>
                        </p>
                    </div>
                </div>
            </section>
            {
                windowWidth > 1024 ?
                null:
                <WhatWeCanDo scrollToSection={scrollToSection}/>
            }
            <ServicesOverview sectionRefs={sectionRefs}/>
        </>
    );
}
 
export default Overview;