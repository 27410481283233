import { useEffect, useState } from "react";

const OurCommitments = () => {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        // Function to update window width
        const handleResize = () => {
          setWindowWidth(window.innerWidth);
        };
    
        // Add event listener
        window.addEventListener('resize', handleResize);
    
        // Clean up event listener on component unmount
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <>
            {/* {
                windowWidth > 1023 ? */}
                    <section className="_our-commitments">
                        <div className="_our-commitments_wrapper _container">
                            <div className="_our-commitments_key-points">
                                <div className="_our-commitments_info">
                                    <h3 className="_our-commitments_title">Why Partner with Us?</h3>
                                </div>
                                <div className="_our-commitments_key-point">
                                    <figure className="_our-commitments_key-point_media_wrapper">
                                        <img className="_our-commitments_key-point_media" src="./assets/media/pages/index/our-commitments/expertise.svg"/>
                                    </figure>
                                    <span className="_our-commitments_key-point_text">
                                        Expertise
                                    </span>
                                </div>
                                <div className="_our-commitments_key-point">
                                    <figure className="_our-commitments_key-point_media_wrapper">
                                        <img className="_our-commitments_key-point_media" src="./assets/media/pages/index/our-commitments/quality.svg"/>
                                    </figure>
                                    <span className="_our-commitments_key-point_text">
                                        Quality Assurance
                                    </span>
                                </div>
                                <div className="_our-commitments_key-point">
                                    <figure className="_our-commitments_key-point_media_wrapper">
                                        <img className="_our-commitments_key-point_media" src="./assets/media/pages/index/our-commitments/client.svg"/>
                                    </figure>
                                    <span className="_our-commitments_key-point_text">
                                    Client-centric
                                    </span>
                                </div>
                                <div className="_our-commitments_key-point">
                                    <figure className="_our-commitments_key-point_media_wrapper">
                                        <img className="_our-commitments_key-point_media" src="./assets/media/pages/index/our-commitments/cost.svg"/>
                                    </figure>
                                    <span className="_our-commitments_key-point_text">
                                    Cost effective
                                    </span>
                                </div>
                                <div className="_our-commitments_key-point">
                                    <figure className="_our-commitments_key-point_media_wrapper">
                                        <img className="_our-commitments_key-point_media" src="./assets/media/pages/index/our-commitments/timely.svg"/>
                                    </figure>
                                    <span className="_our-commitments_key-point_text">
                                    Timely delivery
                                    </span>
                                </div>
                                <div className="_our-commitments_key-point">
                                    <figure className="_our-commitments_key-point_media_wrapper">
                                        <img className="_our-commitments_key-point_media" src="./assets/media/pages/index/our-commitments/support.svg"/>
                                    </figure>
                                    <span className="_our-commitments_key-point_text">
                                    Post-development support
                                    </span>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* :
                    <section className="_our-commitments">
                        <div className="_our-commitments_wrapper _container">
                                <img className="_our-commitments_media_grediant" src="./assets/media/pages/index/our-commitments/gradient-responsive.png"/>
                            <div className="_our-commitments_media_wrapper">
                                <img className="_our-commitments_media" src="./assets/media/pages/index/our-commitments/main1.png"/>
                                <img className="_our-commitments_media_info" src="./assets/media/pages/index/our-commitments/info-responsive.svg"/>
                            </div>
                            <div className="_ideaRealizer">
                                <h3 className="_ideaRealizer_title">Transforming ideas into reality</h3>
                            </div>
                        </div>
                    </section>
            } */}
        </>
    );
}
 
export default OurCommitments;