import { Link, useParams } from "react-router-dom";
import TabsContent from "../../json/TabsContent.json"
import JobForm from "../sections/JobForm";


const JobDetail = () => {
    const { jobId } = useParams();
    const formEntries = TabsContent.formEntries;

    const job = formEntries.find(p => p._id === parseInt(jobId));

    return (
        <>
            <section className="_job-detail">
                <div className="_job-detail_wrapper _container">
                    <div className="_job-detail_info_wrapper">
                        <div className="_job-detail_title_wrapper">
                            <h1 className="_job-detail_title">
                                <Link to="/career">
                                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="27" viewBox="0 0 15 27" fill="none">
                                <path d="M0.762563 12.1805C0.0791456 12.8639 0.0791457 13.972 0.762563 14.6554L11.8995 25.7923C12.5829 26.4758 13.691 26.4758 14.3744 25.7923C15.0578 25.1089 15.0578 24.0009 14.3744 23.3175L4.47487 13.418L14.3744 3.51847C15.0578 2.83506 15.0578 1.72702 14.3744 1.0436C13.691 0.360182 12.5829 0.360182 11.8995 1.0436L0.762563 12.1805ZM4 11.668L2 11.668L2 15.168L4 15.168L4 11.668Z" fill="black"/>
                                </svg>
                                </Link>
                            Job details
                            </h1>
                            <span className="_job-detail_start-date">
                                Start date
                                <span>
                                Immediate
                                </span>
                            </span>
                        </div>
                        <div className="_job-detail_info_overview">
                        <div className="_job-detail_info">
                            <div className="_job-detail_item">
                                <span className="_job-detail_item_title">
                                Job role name
                                </span>
                                <span className="_job-detail_item_val">
                                    {job.jobName}
                                </span>
                            </div>
                            <div className="_job-detail_item">
                                <span className="_job-detail_item_title">
                                Location
                                </span>
                                <span className="_job-detail_item_val">
                                Hybrid/Remote
                                </span>
                            </div>
                            <div className="_job-detail_item">
                                <span className="_job-detail_item_title">
                                Experience
                                </span>
                                <span className="_job-detail_item_val">
                                Intermediate or Senior
                                </span>
                            </div>
                            {
                                job?.jobDesc?.requiredTechStack ?
                                <div className="_job-detail_item">
                                    <span className="_job-detail_item_title">
                                    Required Tech Stack
                                    </span>
                                    <span className="_job-detail_item_val">
                                        {
                                            
                                            job?.jobDesc?.requiredTechStack
                                        }
                                    </span>
                                </div>
                                :
                                null
                            }
                        </div>
                        {
                            job?.jobDesc ?
                                <div className="_job-detail_item">
                                    <span className="_job-detail_item_title">
                                    Job Description
                                    </span>
                                    <div className="_job-detail_item_des_overview">
                                        {
                                            job?.jobDesc?.desc ?
                                            <p className="_job-detail_item_des_wrapper">
                                                {
                                                    job?.jobDesc?.desc && job?.jobDesc?.desc.map((description, index) => (
                                                        <span className="_job-detail_item_des" key={index}>{description}</span>
                                                    ))
                                                }
                                            </p>
                                            :
                                            null
                                        }
                                    </div>
                                </div>
                            :
                            null
                        }
                        {
                            job?.jobDesc ?
                                <div className="_job-detail_item">
                                    <span className="_job-detail_item_title">
                                    Skills
                                    </span>
                                    <div className="_job-detail_item_des_overview">
                                        {
                                            job?.jobDesc?.skills ?
                                            <>
                                                <ol className="_job-detail_item_skill_wrapper">
                                                    {
                                                        job?.jobDesc?.skills && job?.jobDesc?.skills.map((skill, index) => (
                                                            <li className="_job-detail_item_skill" key={index}>{skill}</li>
                                                        ))
                                                    }
                                                </ol>
                                            </>
                                            :
                                            null
                                        }
                                    </div>
                                </div>
                            :
                            null
                        }
                        </div>
                    </div>
                </div>
            </section>
            <JobForm/>
        </>
    );
}
 
export default JobDetail;