import { Link } from "react-router-dom";
import BrandLogo from "../utilities/BrandLogo";

const Footer = () => {
    return (
        <>
            <footer className="_footer">
                <div className="_footer_wrapper _container">
                    <div className="_footer_left">
                        <h6 className="_footer_title">
                            From concept to code, let’s bring your <span className="_footer_title_span"> vision to life.</span>
                        </h6>
                        <div className="_footer_address">
                            <div className="_footer_address_row">
                                <div className="_footer_address_item">
                                    <span className="_footer_address_icon _footer_address_icon_address">
                                        <img src="/assets/media/icons/location.svg"/>
                                    </span>
                                    <p className="_footer_address_text">
                                        <span>
                                            4-91/1, Penamaluru, Vijayawada,
                                        </span>
                                        <span>
                                            Krishna district, Pin code 521137 
                                        </span>
                                        <span>
                                            Andhra Pradesh, India.
                                        </span>
                                    </p>
                                </div>
                                <div className="_footer_address_item">
                                    <span className="_footer_address_icon">
                                        <img src="/assets/media/icons/email.svg"/>
                                    </span>
                                    <Link to="mailto:hr@mindcres.com" className="_footer_address_link">
                                        hr@mindcres.com
                                        </Link>
                                </div>
                            </div>
                            <div className="_footer_address_row">
                                <div className="_footer_address_item">
                                    <span className="_footer_address_icon">
                                        <img src="/assets/media/icons/phone.svg"/>
                                    </span>
                                    <Link to="tel:+917901054999" className="_footer_address_link">
                                        + 91 7901054999
                                        </Link>
                                </div>
                                <div className="_footer_address_item">
                                    <span className="_footer_address_icon">
                                        <img src="/assets/media/icons/devicon_linkedin.svg"/>
                                    </span>
                                    <a target="_blank" href="https://www.linkedin.com/company/viaticum-in/" className="_footer_address_link">
                                        LinkedIn
                                        </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="_footer_right">
                        <div className="_footer_right_wrapper">
                            <Link to="/">
                                <BrandLogo brandColor="#000000"/>
                            </Link>
                            <span className="_footer_right_label">Innovate the Future</span>
                        </div>
                    </div>
                </div>
                {/* <div class="_footer_by">
                    <ul>
                        <li>Design</li>
                        <li>.</li>
                        <li>Development</li>
                        <li>.</li>
                        <li>Direction</li>
                    </ul>
                    <span>By</span>
                    <a href="https://mindcres.com/">MindCres Technoligies</a>
                </div> */}
            </footer>
        </>
    );
}
 
export default Footer;