import { useEffect, useState } from "react";
import Hero from "../sections/Hero";
import tabsInfo from "../../json/tabsInfo.json"
import TabsContent from "../../json/TabsContent.json"
import { Tab, TabContent } from "../Tab";


import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";

gsap.registerPlugin(ScrollTrigger);

const Career = () => {
    const tabs = tabsInfo.tabInfo;
    const formEntries = TabsContent.formEntries;

    const [visibleTab, setVisibleTab] = useState(tabs[0]._tabId);

    useGSAP(() => {
        const loadTl = gsap.timeline({ defaults: { opacity: 0, ease: 'ease-in', duration: 1 } });
    
        const pageFadeIn = () => {
          const tl = gsap.timeline();
          tl.from('body', { opacity: 0, ease: 'linear', duration: 1 });
          return tl;
        };
        loadTl.add(pageFadeIn());
      }, []);

    return (
        <>
            <Hero page="career" title="Work with MindCres" desc="Shape the future with cutting-edge technology and talent. Discover exciting career opportunities and contribute to innovative solutions. "/>
            <section className="_career">
                <div className="_container _career_wrapper">
                    <h2 className="_career_title _sub_h2">Open Positions</h2>
                    <div className="_career-section">
                    {/* <div className="_career-section_head">
                        <ul className="_tab_head">
                        {tabs.map((tab) => (
                            <Tab
                            key={tab._tabId}
                            tab={tab}
                            visibleTab={visibleTab}
                            setVisibleTab={setVisibleTab}
                            />
                        ))}
                        </ul>
                    </div> */}
                    <div className="_career-section_body">
                        <div className="_tab_body">
                        {/* {tabs.map((tab) => ( */}
                            <TabContent
                                // key={tab._tabId}
                                // tab={tab}
                                visibleTab={visibleTab}
                                formEntries={formEntries}
                            />
                        {/* ))} */}
                        </div>
                    </div>
                    </div>
                </div>
            </section>
        </>
    );
}
 
export default Career;