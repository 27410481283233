import BrandLogo from "../utilities/BrandLogo";

const JoinUsWay = () => {
    return (
        <>
            <div className="_join-us_way_overview">
                <div className="_join-us_way_wrapper _container">
                    <div className="_join-us_way_info">
                        <h2 className="_join-us_way_title">Become a part of <span><BrandLogo/></span></h2>
                    </div>
                    <div className="_join-us_way">
                        <div className="_join-us_way_proccess">
                            <div className="_join-us_way_step">
                                <div className="_join-us_way_step_info">
                                    <p className="_join-us_way_step_title">Send us your CV</p>
                                    <span className="_join-us_way_step_typo">
                                        Submit your CV to begin <br /> your journey with us.
                                        <a href="mailto:hr@mindcres.com" target="_blank">hr@mindcres.com</a>
                                    </span>
                                </div>
                                <div><img className="_join-us_way_logo" src="/assets/media/pages/career/join-us/logo1.svg" loading="lazy" alt="Imaginary Cloud illustration" /></div>
                            </div>
                            <div className="_join-us_way_step _join-us_way_step_left">
                                <div className="_join-us_way_step_info">
                                    <p className="_join-us_way_step_title">HR screening</p>
                                    <span className="_join-us_way_step_typo">Join us for an HR screening<br />to explore your fit.</span>
                                </div>
                                <div><img className="_join-us_way_logo _join-us_way_logo_right" src="/assets/media/pages/career/join-us/logo2.svg" loading="lazy" alt="Imaginary Cloud illustration" /></div>
                            </div>
                            <div className="_join-us_way_step ">
                                <div className="_join-us_way_step_info">
                                    <p className="_join-us_way_step_title">Technical assessment round</p>
                                    <span className="_join-us_way_step_typo">Complete a technical assessment to<br /> showcase your skills.</span>
                                </div>
                                <div><img className="_join-us_way_logo" src="/assets/media/pages/career/join-us/logo3.svg" loading="lazy" alt="Imaginary Cloud illustration" /></div>
                            </div>
                            <div className="_join-us_way_step  _join-us_way_step_left ">
                                <div className="_join-us_way_step_info">
                                    <p className="_join-us_way_step_title">Receive your offer</p>
                                    <span className="_join-us_way_step_typo">Receive a warm offer and details on <br /> moving forward.</span>
                                </div>
                                <div><img className="_join-us_way_logo _join-us_way_logo_right" src="/assets/media/pages/career/join-us/logo4.svg" loading="lazy" alt="Imaginary Cloud illustration" /></div>
                            </div>
                            <div className="_join-us_way_step_divider"></div>
                        </div>
                        <p className="_join-us_way_footer_text">Join our exceptionl team of professionals</p>

                    </div>
                </div>
            </div>



        </>
    );
}
 
export default JoinUsWay;