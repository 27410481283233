import { useGSAP } from '@gsap/react';
import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Header from '../Header';
import Hero from '../sections/Hero';
import FutureInnovation from '../sections/FutureInnovation';
import OurCommitments from '../sections/OurCommitments';
import OurServices from '../sections/OurServices';
import AboutUs from '../sections/AboutUs';
import GetInTouch from '../sections/GetInTouch';
import Footer from '../sections/Footer';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import Test from '../sections/Test';

gsap.registerPlugin(ScrollTrigger);
ScrollTrigger.normalizeScroll(true);

const Index = () => {

  // const { pathname } = useLocation();

  // useEffect(() => {
  //   window.scrollTo(0, 0); // Reset scroll position on route change
  // }, [pathname]);
  

  useGSAP(() => {
      ScrollTrigger.matchMedia({
        "(min-width: 1280px)": function() {
  
          gsap.to("._future-innovation_media_wrapper", {
            width: "60%", // Adjust this value as needed
            ease: "none",
            scrollTrigger: {
              // markers: "isDevelopment",
              trigger: "._hero",
              start: "bottom top",
              end: "+=100% top",
              scrub:true,
              end: "+=100% center",
              toggleActions: "restart pause reverse pause"
            }
          });
  
          // title opacity 0 for _future-innovation section
          gsap.to("._future-innovation_info", {
            opacity: 0,
            scrollTrigger: {
              // markers: "isDevelopment",
              trigger: "._hero",
              start: "bottom 20%",
              end: "+=50% top",
              scrub: true,
              toggleActions: "restart pause reverse pause"
            }
          });
      
          // translate to top future innovation section
          gsap.to("._future-innovation", {
            yPercent: -20,
            ease: "none",
            scrollTrigger: {
              trigger: "._hero",
              start: "bottom top",
              end: "+=100% top",
              // markers:true,
              scrub: 1
            }, 
          });
  
          // Pin the section
          // gsap.timeline({
          //   scrollTrigger: {
          //     trigger: "._our-commitments",
          //     start: "top top",
          //     end: "+=150% top",
          //     scrub: 1.1,
          //     pin: "._our-commitments", // Pin the section
          //     pinSpacing: true, // Prevent extra space from being added
          //     // markers: {startColor: "purple", endColor: "purple", fontSize: "12px"}      
          //   }
          // })
  
          gsap.fromTo("._our-commitments_title",{
            opacity: 1,
          }, {
            opacity: 0,
            ease: "none",
            scrollTrigger: {
              // markers: "isDevelopment",
              trigger: "._our-commitments",
              start: "top 20%",
              end: "+=30% top",
              scrub: true,
              // markers: {startColor: "red", endColor: "red", fontSize: "20px"},
              // toggleActions: "restart pause reverse pause"
            }
          })
  
          // gsap.fromTo("._our-commitments_key-points",{
          //   yPercent:0
          // }, {
          //   yPercent:-70,
          //   ease: "none",
          //   scrollTrigger: {
          //     // markers: "isDevelopment",
          //     trigger: "._our-commitments",
          //     start: "top top",
          //     end: "+=150% top",
          //     scrub: true,
          //     // markers: {startColor: "red", endColor: "red", fontSize: "20px"},
          //     // toggleActions: "restart pause reverse pause"
          //   }
          // })
          
          const elementsMedia = document.querySelectorAll("._our-commitments_key-point");
          
          elementsMedia.forEach((element, index) => {
  
            let xPercentEnd;
            let start;
  
            // Assign xPercentEnd based on the index
            switch (index) {
                case 0:
                    xPercentEnd = 0;
                    start = "100% +=120%"
                    break;
                case 1:
                    xPercentEnd = -21;
                    start = "120% +=120%"
                    break;
                case 2:
                    xPercentEnd = 7;
                    start = "130% +=120%"
                    break;
                case 3:
                    xPercentEnd = -14;
                    start = "140% +=120%"
                    break;
                case 4:
                  xPercentEnd = 14;
                  start = "140% +=125%"
                  break;
                default:
                  start = "140% +=125%"
                  xPercentEnd = 0; // Default to 0 if index doesn't match any case
            }
  
            const isOdd = index % 2 !== 0;
              const xPercentStart = isOdd ? 150 : -150;
            
              // Apply GSAP animation to each element
              gsap.fromTo(element, {
                xPercent: xPercentStart,
                opacity: 0,
              },{
                  xPercent: xPercentEnd,
                  opacity: 1,
                  ease: "none",
                  delay:0.3,
                  scrollTrigger: {
                      trigger: element,
                      start: start,
                      end: "+=10% bottom",
                      // markers: true,
                      // toggleActions: "restart pause reverse restart",
                      // toggleActions: "restart none none reset",
                      // toggleActions: "restart none none pause",
                      // onEnterBack: () => {
                      //   element.classList.add("a");
                      //   element.style.transform 
                      // },
                      // scrub: 1
                    },
              });
          });
  
          gsap.fromTo("._about-us_section_title", {
            xPercent: -150,
            opacity: 0,
            delay:0.3,
          },{
              xPercent: 0,
              opacity: 1,
              ease: "none",
              scrollTrigger: {
                trigger: "._about-us_section_title",
                start: "0% bottom",
                end: "+=10% bottom",
                // markers: true,
                // toggleActions: "restart none none reset",
                // toggleActions: "restart pause reverse restart",
                // scrub: 1
              },
          });

          gsap.fromTo("._our-services_section_title", {
            xPercent: -150,
            opacity: 0,
            delay:0.3,
          },{
              xPercent: 0,
              opacity: 1,
              ease: "none",
              scrollTrigger: {
                trigger: "._our-services_section_title",
                start: "0% bottom",
                end: "+=10% bottom",
                // markers: true,
                // toggleActions: "restart none none reset",
                // toggleActions: "restart pause reverse restart",
                // scrub: 1
              },
          });
  
          gsap.fromTo("._get_in_touch_title_main", {
            xPercent: -150,
            opacity: 0,
            delay:0.3
          },{
              xPercent: 0,
              opacity: 1,
              ease: "none",
              scrollTrigger: {
                trigger: "._get_in_touch_title_main",
                start: "0% bottom",
                end: "+=10% bottom",
                // markers: true,
                
                // toggleActions: "restart none none reset",
                // toggleActions: "restart pause reverse restart",
                // scrub: 1
              },
          });
        },
        "(min-width: 1024px) and (max-width: 1280px)": function() {
  
          gsap.to("._future-innovation_media_wrapper", {
            width: "60%", // Adjust this value as needed
            ease: "none",
            scrollTrigger: {
              // markers: "isDevelopment",
              trigger: "._hero",
              start: "bottom top",
              end: "+=100% top",
              scrub:true,
              end: "+=100% center",
              toggleActions: "restart pause reverse pause"
            }
          });
  
          // title opacity 0 for _future-innovation section
          gsap.to("._future-innovation_info", {
            opacity: 0,
            scrollTrigger: {
              // markers: "isDevelopment",
              trigger: "._hero",
              start: "bottom 20%",
              end: "+=50% top",
              scrub: true,
              toggleActions: "restart pause reverse pause"
            }
          });
  
          gsap.fromTo("._our-commitments_title",{
            opacity: 1,
          }, {
            opacity: 0,
            ease: "none",
            scrollTrigger: {
              // markers: "isDevelopment",
              trigger: "._our-commitments",
              start: "top 20%",
              end: "+=30% top",
              scrub: true,
              // markers: {startColor: "red", endColor: "red", fontSize: "20px"},
              // toggleActions: "restart pause reverse pause"
            }
          })
          
          const elementsMedia = document.querySelectorAll("._our-commitments_key-point");
          
          elementsMedia.forEach((element, index) => {
  
            let xPercentEnd;
            let start;
  
            // Assign xPercentEnd based on the index
            switch (index) {
                case 0:
                    xPercentEnd = 0;
                    start = "100% 120%"
                    break;
                case 1:
                    xPercentEnd = -21;
                    start = "120% 120%"
                    break;
                case 2:
                    xPercentEnd = 7;
                    start = "130% 120%"
                    break;
                case 3:
                    xPercentEnd = -14;
                    start = "130% 120%"
                    break;
                case 4:
                  xPercentEnd = 14;
                  start = "140% 125%"
                  break;
                default:
                  start = "140% 125%"
                  xPercentEnd = 0; // Default to 0 if index doesn't match any case
            }
  
            const isOdd = index % 2 !== 0;
              const xPercentStart = isOdd ? 150 : -150;
            
              // Apply GSAP animation to each element
              gsap.fromTo(element, {
                xPercent: xPercentStart,
                opacity: 0,
              },{
                  xPercent: xPercentEnd,
                  opacity: 1,
                  ease: "none",
                  delay:0.3,
                  scrollTrigger: {
                      trigger: element,
                      start: start,
                      end: "+=10% bottom",
                      // markers: true,
                      // toggleActions: "restart pause reverse restart",
                      // toggleActions: "restart none none reset",
                      
                      // scrub: 1
                    },
              });
          });
  
          gsap.fromTo("._about-us_section_title", {
            xPercent: -150,
            opacity: 0,
          },{
              xPercent: 0,
              opacity: 1,
              ease: "none",
              scrollTrigger: {
                trigger: "._about-us_section_title",
                start: "0% bottom",
                end: "+=10% bottom",
                // markers: true,
                      // toggleActions: "restart none none reset",
                      // toggleActions: "restart pause reverse restart",
                      // scrub: 1
              },
          });
          gsap.fromTo("._get_in_touch_title_main", {
            xPercent: -150,
            opacity: 0,
          },{
              xPercent: 0,
              opacity: 1,
              ease: "none",
              scrollTrigger: {
                trigger: "._get_in_touch_title_main",
                start: "top top",
                end: "+=10% center",
                // markers: true,
                // toggleActions: "restart pause reverse restart",
                // toggleActions: "restart none none reset",
                scrub: 1
              },
          });
        },
        "(min-width: 768px) and (max-width: 1024px)": function() {
          gsap.to("._future-innovation_media_wrapper", {
            width: "80%", // Adjust this value as needed
            ease: "none",
            scrollTrigger: {
              // markers: "isDevelopment",
              trigger: "._hero",
              start: "bottom top",
              end: "+=100% top",
              scrub:true,
              end: "+=100% center",
              toggleActions: "restart pause reverse pause"
            }
          });
  
          // title opacity 0 for _future-innovation section
          gsap.to("._future-innovation_info", {
            opacity: 0,
            scrollTrigger: {
              // markers: "isDevelopment",
              trigger: "._hero",
              start: "bottom 20%",
              end: "+=50% top",
              scrub: true,
              toggleActions: "restart pause reverse pause"
            }
          });
  
          gsap.fromTo("._our-commitments_title",{
            opacity: 1,
          }, {
            opacity: 0,
            ease: "none",
            scrollTrigger: {
              // markers: "isDevelopment",
              trigger: "._our-commitments",
              start: "top 20%",
              end: "+=30% top",
              scrub: true,
              // markers: {startColor: "red", endColor: "red", fontSize: "20px"},
              // toggleActions: "restart pause reverse pause"
            }
          })
          
          const elementsMedia = document.querySelectorAll("._our-commitments_key-point");
          
          elementsMedia.forEach((element, index) => {
  
            let xPercentEnd;
            let start;
  
            // Assign xPercentEnd based on the index
            switch (index) {
                case 0:
                    xPercentEnd = 0;
                    start = "100% 120%"
                    break;
                case 1:
                    xPercentEnd = -21;
                    start = "150% 120%"
                    break;
                case 2:
                    xPercentEnd = 7;
                    start = "150% 120%"
                    break;
                case 3:
                    xPercentEnd = -14;
                    start = "150% 120%"
                    break;
                case 4:
                  xPercentEnd = 14;
                  start = "150% 125%"
                  break;
                default:
                  start = "150% 125%"
                  xPercentEnd = 0; // Default to 0 if index doesn't match any case
            }
  
            const isOdd = index % 2 !== 0;
              const xPercentStart = isOdd ? 150 : -150;
            
              // Apply GSAP animation to each element
              gsap.fromTo(element, {
                xPercent: xPercentStart,
                opacity: 0,
              },{
                  xPercent: xPercentEnd,
                  opacity: 1,
                  ease: "none",
                  scrollTrigger: {
                      trigger: element,
                      start: start,
                      end: "+=10% bottom",
                      // markers: true,
                      // toggleActions: "play none none reverse",
                      // toggleActions: "restart none none reset",
                      // toggleActions: "restart pause reverse restart",
                      // scrub: 1
                    },
              });
          });
  
          gsap.fromTo("._about-us_section_title", {
            xPercent: -150,
            opacity: 0,
          },{
              xPercent: 0,
              opacity: 1,
              ease: "none",
              scrollTrigger: {
                trigger: "._about-us_section_title",
                start: "0% bottom",
                end: "+=10% bottom",
                // markers: true,
                // toggleActions: "restart pause reverse restart",
                // toggleActions: "restart none none reset",
                scrub: 1
              },
          });
          gsap.fromTo("._get_in_touch_title_main", {
            xPercent: -150,
            opacity: 0,
          },{
              xPercent: 0,
              opacity: 1,
              ease: "none",
              scrollTrigger: {
                trigger: "._get_in_touch_title_main",
                start: "0% bottom",
                end: "+=10% bottom",
                // markers: true,
                // toggleActions: "restart pause reverse restart",
                // toggleActions: "restart none none reset",
                scrub: 1
              },
          });
        },
        "(min-width: 480px) and (max-width: 768px)": function() {
  
          gsap.to("._future-innovation_media_wrapper", {
            width: "110%", // Adjust this value as needed
            ease: "none",
            scrollTrigger: {
              // markers: "isDevelopment",
              trigger: "._hero",
              start: "bottom center",
              end: "+=100% top",
              scrub:true,
              toggleActions: "restart pause reverse pause"
            }
          });
  
          // title opacity 0 for _future-innovation section
          gsap.to("._future-innovation_info", {
            opacity: 0,
            scrollTrigger: {
              // markers: "isDevelopment",
              trigger: "._hero",
              start: "bottom 20%",
              end: "+=50% top",
              scrub: true,
              toggleActions: "restart pause reverse pause"
            }
          });
  
          gsap.fromTo("._our-commitments_title",{
            opacity: 1,
          }, {
            opacity: 0,
            ease: "none",
            scrollTrigger: {
              // markers: "isDevelopment",
              trigger: "._our-commitments",
              start: "top 20%",
              end: "+=30% top",
              scrub: true,
              // markers: {startColor: "red", endColor: "red", fontSize: "20px"},
              // toggleActions: "restart pause reverse pause"
            }
          })
          
          const elementsMedia = document.querySelectorAll("._our-commitments_key-point");
          
          elementsMedia.forEach((element, index) => {
  
            let xPercentEnd;
            let start;
  
            // Assign xPercentEnd based on the index
            switch (index) {
                case 0:
                    xPercentEnd = 0;
                    start = "100% 115%"
                    break;
                case 1:
                    xPercentEnd = 0;
                    start = "150% 115%"
                    break;
                case 2:
                    xPercentEnd = 0;
                    start = "150% 115%"
                    break;
                case 3:
                    xPercentEnd = 0;
                    start = "150% 115%"
                    break;
                case 4:
                  xPercentEnd = 0;
                  start = "150% 115%"
                  break;
                default:
                  start = "150% 115%"
                  xPercentEnd = 0; // Default to 0 if index doesn't match any case
            }
  
            const isOdd = index % 2 !== 0;
              const xPercentStart = isOdd ? 150 : -150;
            
              // Apply GSAP animation to each element
              gsap.fromTo(element, {
                // xPercent: xPercentStart, //after
                opacity: 0,
              },{
                  // xPercent: xPercentEnd, //after
                  opacity: 1,
                  ease: "none",
                  delay:0.3,
                  scrollTrigger: {
                      trigger: element,
                      start: start,
                      end: "+=10% bottom",
                      // markers: true,
                      // toggleActions: "restart pause reverse restart",
                      // scrub: 1
                      // toggleActions: "restart none none reset",
                      
                    },
              });
          });
  
          gsap.fromTo("._about-us_section_title", {
            xPercent: -150,
            opacity: 0,
          },{
              xPercent: 0,
              opacity: 1,
              ease: "none",
              scrollTrigger: {
                trigger: "._about-us_section_title",
                start: "0% bottom",
                end: "+=10% bottom",
                // markers: true,
                // toggleActions: "restart pause reverse restart",
                // toggleActions: "restart none none reset",
                scrub: 1
              },
          });

          // gsap.fromTo(".heading_h2", {
          //   xPercent: -150,
          //   opacity: 0,
          // },{
          //     xPercent: 0,
          //     opacity: 1,
          //     ease: "none",
          //     scrollTrigger: {
          //       trigger: ".heading_h2",
          //       start: "0% bottom",
          //       end: "+=10% bottom",
          //       markers: true,
          //       // toggleActions: "restart pause reverse restart",
          //       toggleActions: "restart none none reset",
          //       scrub: 1
          //     },
          // });

          setTimeout(() => {
            gsap.fromTo("._get_in_touch_title_main", {
              xPercent: -150,
              opacity: 0,
            },{
                xPercent: 0,
                opacity: 1,
                ease: "none",
                scrollTrigger: {
                  trigger: "._get_in_touch_title_main",
                  start: "top bottom",
                  end: "+=10% bottom",
                  // markers: true,
                  // toggleActions: "restart none none reset",
                  // toggleActions: "restart none none reset",
    
                  scrub: 1
                },
            });

          }, 1500)

        },
        "(max-width: 480px)": function() {
  
          gsap.to("._future-innovation_media_wrapper", {
            width: "100%", // Adjust this value as needed
            ease: "none",
            scrollTrigger: {
              // markers: "isDevelopment",
              trigger: "._hero",
              start: "bottom center",
              end: "+=100% top",
              scrub:true,
              toggleActions: "restart pause reverse pause"
            }
          });
  
          // title opacity 0 for _future-innovation section
          gsap.to("._future-innovation_info", {
            opacity: 0,
            scrollTrigger: {
              // markers: "isDevelopment",
              trigger: "._hero",
              start: "bottom 20%",
              end: "+=50% top",
              scrub: true,
              toggleActions: "restart pause reverse pause"
            }
          });
  
          gsap.fromTo("._our-commitments_title",{
            opacity: 1,
          }, {
            opacity: 0,
            ease: "none",
            scrollTrigger: {
              // markers: "isDevelopment",
              trigger: "._our-commitments",
              start: "top 20%",
              end: "+=30% top",
              scrub: true,
              // markers: {startColor: "red", endColor: "red", fontSize: "20px"},
              // toggleActions: "restart pause reverse pause"
            }
          })
          
          const elementsMedia = document.querySelectorAll("._our-commitments_key-point");
          
          elementsMedia.forEach((element, index) => {
  
            let xPercentEnd;
            let start;
  
            // Assign xPercentEnd based on the index
            switch (index) {
                case 0:
                    xPercentEnd = 0;
                    start = "100% 110%"
                    break;
                case 1:
                    xPercentEnd = 0;
                    start = "150% 110%"
                    break;
                case 2:
                    xPercentEnd = 0;
                    start = "150% 110%"
                    break;
                case 3:
                    xPercentEnd = 0;
                    start = "150% 110%"
                    break;
                case 4:
                  xPercentEnd = 0;
                  start = "150% 110%"
                  break;
                default:
                  start = "150% 110%"
                  xPercentEnd = 0; // Default to 0 if index doesn't match any case
            }
  
            const isOdd = index % 2 !== 0;
              const xPercentStart = isOdd ? 150 : -150;
            
              // Apply GSAP animation to each element
              gsap.fromTo(element, {
                // xPercent: xPercentStart, //after
                opacity: 0,
              },{
                  // xPercent: xPercentEnd, //after
                  opacity: 1,
                  ease: "none",
                  delay:0.3,
                  scrollTrigger: {
                      trigger: element,
                      start: start,
                      end: "+=10% bottom",
                      // markers: true,
                      // toggleActions: "restart none none reset",
                      // once: true, // Ensures animation plays only once
                      // toggleActions: "restart pause reverse restart",
                      // scrub: 1
                    },
              });
          });
  
          // gsap.fromTo("._about-us_section_title", {
          //   xPercent: -150,
          //   opacity: 0,
          // },{
          //     xPercent: 0,
          //     opacity: 1,
          //     ease: "none",
          //     delay:0.3,
          //     scrollTrigger: {
          //       trigger: "._about-us_section_title",
          //       start: "0% bottom",
          //       end: "+=10% bottom",
          //       // markers: true,
          //       // toggleActions: "restart pause reverse restart",
          //       // toggleActions: "restart none none reset",
                
          //       // scrub: 1
          //     },
          // });
  
          // gsap.fromTo("._get_in_touch_title_main", {
          //   xPercent: -150,
          //   opacity: 0,
          // },{
          //     xPercent: 0,
          //     opacity: 1,
          //     ease: "none",
          //     delay:0.3,
          //     scrollTrigger: {
          //       trigger: "._form_wrapper",
          //       start: "top bottom",
          //       end: "+=10% bottom",
          //       // markers: true,
          //       // toggleActions: "restart pause reverse restart",
          //       // toggleActions: "restart none none reset",
                
          //       // scrub: 1
          //     },
          // });
  
        }
      })
  });
  
  return (
      <>
          <Hero btn="Let’s Connect" page={"index"} path="/contact-us"/>
          <FutureInnovation/>
          <OurCommitments/>
          <AboutUs/>
          <OurServices/>
          {/* <Test/> */}
          <GetInTouch />
      </>
  );
}
 
export default Index;