import { useEffect, useState } from "react";

const AboutUs = () => {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        // Function to update window width
        const handleResize = () => {
          setWindowWidth(window.innerWidth);
        };
    
        // Add event listener
        window.addEventListener('resize', handleResize);
    
        // Clean up event listener on component unmount
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <>
            <section className="_about-us_section">
                <div className="_about-us_section_bg">
                {
                    windowWidth > 1149 ?
                    <svg xmlns="http://www.w3.org/2000/svg" width={1728} height={688} viewBox="0 0 1728 688" fill="none">
                        <path d="M108.417 498.698L-121.612 688H1728V0.263672H1691.5C1482.85 0.263672 1288.76 67.2116 1177.23 177.646L1171.01 183.8C1053.87 299.8 843.604 365.5 625.168 354.361C424.889 344.148 230.106 398.554 108.417 498.698Z" fill="#FEECB1" />
                        <path d="M-0.000366211 630.798H1728V688L-0.000366211 688V630.798Z" fill="url(#paint0_linear_2710_19393)" />
                        <defs>
                            <linearGradient id="paint0_linear_2710_19393" x1={864} y1={688} x2={864} y2="632.021" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#FAFAFA" />
                                <stop offset={1} stopColor="#FAFAFA" stopOpacity={0} />
                            </linearGradient>
                        </defs>
                    </svg>
                    :
                    <img src="./assets/media/pages/index/about-us/bg.png"/>
                }
                </div>
                <h2 className="_about-us_section_title _sub_h2">About us</h2>
                <div className="_about-us_section_wrapper _container">
                    <div className="_about-us_section_media_wrapper">
                        <img className="_about-us_section_media" src='./assets/media/pages/index/about-us/media.png'/>
                    </div>
                    <div className="_about-us_section_info">
                        <h3 className=" _about-us_section_sub-title">We strive for excellence at every step</h3>
                        <p>
                        At Mindcres Technologies , we are a leading provider of professional software development services with 5+ years of experience in the industry. We have established ourselves as a reliable and trusted partner for businesses of all sizes. 
                        </p>                        
                        <figure className="_about-us_section_media_wrapper _about-us_section_media_wrapper_responsive">
                            <img className="_about-us_section_media" src='./assets/media/pages/index/about-us/media-res.png'/>
                        </figure>
                        <p>
                        Our team of highly skilled and experienced developers is dedicated to delivering quality software solutions that meet our clients' unique requirements. We specialise in developing custom software applications, web and mobile applications, and software integrations.
                        </p>
                    </div>
                </div>
            </section>
        </>
    );
}
 
export default AboutUs;